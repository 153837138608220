import React from "react";
import { ReactElement } from "react";

function RightArrowIcon(): ReactElement {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.78129 4.83312L5.20529 1.25712L6.14796 0.314453L11.3333 5.49979L6.14796 10.6851L5.20529 9.74245L8.78129 6.16645H0.666626V4.83312H8.78129Z"
        fill="#01459E"
      />
    </svg>
  );
}

export { RightArrowIcon };
