/// <reference types="react-scripts" />
declare namespace NodeJS {
    interface ProcessEnv {
        REACT_APP_BASE_URL: string;
        REACT_APP_OIDC_AUTHORITY: string;
        REACT_APP_OIDC_CLIENTID: string;
        REACT_APP_OIDC_REDIRECT_URL: string;
        REACT_APP_GEONORGE_MUNICIPALS_URL: string;
        REACT_APP_CHILD_RESPONDENT_ROLE_ID: string;
        REACT_APP_PARENT1_RESPONDENT_ROLE_ID: string;
        REACT_APP_PARENT2_RESPONDENT_ROLE_ID: string;
        REACT_APP_KINDERGARTENTEACHER_RESPONDENT_ROLE_ID: string;
        REACT_APP_TEACHER_RESPONDENT_ROLE_ID: string;
        REACT_APP_FEATURE_SCHOOLCLASS_ASSESSMENT: string;
        REACT_APP_FEATURE_ASSESSMENT_STATUS: string;
        REACT_APP_FEATURE_HEIGHT_AND_WEIGHT_ASSESSMENT: string;
    }
}

declare global {
    interface Window {
        // eslint-disable-next-line
        env: NodeJS.ProcessEnv;
    }
}

export const env: NodeJS.ProcessEnv = { ...process.env, ...window.env };