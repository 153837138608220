import { env } from "../env";

export interface Respondent {
  id: string;
  name: string;
  nationalIdNumber: string;
  respondentRoleType: string;
  roleId: string;
  givenName: string;
  middleName: string;
  familyName: string;
  phoneNumber: string;
  parents: string[];
  bodyHeight: number;
  bodyWeight: number;
  followUpGroup: number;
  birthDate: string;
  gender: string;
  password: string;
  isEnabled: boolean;
  isDefaultParent: boolean;
  assessmentStatus: string;
  assessments: AssessmentModel[];
  individualAssessments: IndividualAssessmentModel[];
}

export interface School {
  id: string;
  name: string;
}

export interface SchoolClass {
  id: string;
  name: string;
  schoolId: string;
  schoolYear: string;
}

export interface HealthcheckType {
  id: string;
  name: string;
  respondentGroupId: string;
  treatmentTypeId: string;
}

export interface IndividualAssessmentCreateModel {
  id: string;
  schoolClassAssessmentId?: string;
  name: string;
  respondents: Respondent[];
  healthCheckTypeId: string;
  schoolId?: string | null;
  clinicianId: string;
  additionalClinicianId?: string;
  createdDateTime?: string;
  assessmentDate: string;
  healthCenterId: string;
  assessmentStatus: string;
}
export interface IndividualAssessmentModel {
  id: string;
  name: string;
  schoolClassAssessmentId?: string;
  children: Respondent[];
  parents: Respondent[];
  teachers: Respondent[];
  respondents: Respondent[];
  healthCheckTypeId: string;
  schoolId?: string | null;
  clinicianId: string;
  additionalClinicianId?: string;
  createdDateTime?: string;
  assessmentDate: string;
  assessmentStatus: string;
  healthCenterId: string;
}

export interface HealthCenterModel {
  id: string;
  name: string;
  organizationNo?: string;
  herId: string;
  municipalityId: string;
}

export interface MunicipalityModel {
  id: string;
  name: string;
  municipalityNo: string;
}

export interface RespondentRoleAssessmentPlan {
  id: string;
  name: string;
  healthCenterId: string;
  healthCheckTypeId: string;
  respondentRoleId: string | [];
  assessmentPlanId: string;
}

export enum RespondentRoleTypes {
  kindergartenteacher = parseInt(
    env.REACT_APP_KINDERGARTENTEACHER_RESPONDENT_ROLE_ID
  ),
  child = parseInt(env.REACT_APP_CHILD_RESPONDENT_ROLE_ID),
  parent1 = parseInt(env.REACT_APP_PARENT1_RESPONDENT_ROLE_ID),
  parent2 = parseInt(env.REACT_APP_PARENT2_RESPONDENT_ROLE_ID),
  teacher = parseInt(env.REACT_APP_TEACHER_RESPONDENT_ROLE_ID),
}

export interface CheckwareModel {
  id: string;
  name: string;
}

export interface TreatmentModel {
  treatmentTypeId: string;
  name: string;
}

export interface RespondGroupModel {
  respondentGroupId: string;
  name: string;
}

export interface UserInfo {
  isAuthenticated: boolean;
  claims: Record<string, string>;
}

export interface User {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  nationalIdNumber: string;
  userRoleType: string;
  isAdmin: boolean;
  currentHealthCenterId: string;
}

export interface AssessmentModel {
  individualAssessmentId: string;
  nationalIdNumber: string;
  assessmentInstanceReportId: string;
  assessmentInstanceReportCheckWareId: number;
  assessmentStatus: string;
  errorCode?: number;
  errorMessage?: string;
}

export interface IndividualAssessmentModel {
  id: string;
  name: string;
  clinicianId: string;
  createdDateTime?: string;
  assessmentDate: string;
  assessmentStatus: string;
  child: Respondent;
}
