import React from "react";
import { Heading, VStack, Text } from "@chakra-ui/react";

const FeatureDisabled: React.FC = () => {
  return (
    <VStack spacing="16px" align={"flex-start"}>
      <Heading size="md">Funksjonen er under utvikling</Heading>
      <Text>Blir klar i løpet av 2024.</Text>
    </VStack>
  );
};

export default FeatureDisabled;
