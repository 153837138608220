import * as React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container, Box, Text } from "@chakra-ui/react";
import { Header } from "../components/Header";
import { Navbar } from "../components/Navbar";
import { useUserInfo, useUser } from "../api/hooks";
import { env } from "../env";

export const Layout = () => {
  const { data: userInfo } = useUserInfo();
  const { data: user } = useUser();
  const isAdmin = user?.userRoleType == "SystemAdmin";
  const location = useLocation();

  return (
    <>
      <Header />
      {userInfo?.isAuthenticated && !!!isAdmin && (
        <Navbar
          links={[
            {
              name: "Individuell kartlegging",
              path: "/IndividualMapping",
            },
            {
              name: "Kartlegging skoleklasse",
              path: "/SchoolClassAssessment",
              disabled:
                env.REACT_APP_FEATURE_SCHOOLCLASS_ASSESSMENT === "false",
            },
            {
              name: "Status kartlegging",
              path: "/AssessmentStatus",
            },
            // {
            //   name: "Registrer høyde og vekt",
            //   path: "/heightAndWeightAssessment",
            //   disabled:
            //     env.REACT_APP_FEATURE_HEIGHT_AND_WEIGHT_ASSESSMENT === "false",
            // },
          ]}
        />
      )}
      {userInfo?.isAuthenticated && isAdmin && (
        <Navbar
          links={[
            {
              name: "Administrasjon",
              path: "/Administration",
            },
            {
              name: "Individuell kartlegging",
              path: "/IndividualMapping",
            },
            {
              name: "Kartlegging skoleklasse",
              path: "/SchoolClassAssessment",
              disabled:
                env.REACT_APP_FEATURE_SCHOOLCLASS_ASSESSMENT === "false",
            },
            {
              name: "Status kartlegging",
              path: "/AssessmentStatus",
            },
            // {
            //   name: "Registrer høyde og vekt",
            //   path: "/heightAndWeightAssessment",
            //   disabled:
            //     env.REACT_APP_FEATURE_HEIGHT_AND_WEIGHT_ASSESSMENT === "false",
            // },
          ]}
        />
      )}
      <Box color="GB.DarkGrey" minHeight="calc(100vh - 80px)">
        <Container
          maxW={location.pathname === "/Administration" ? "1280px" : "1012px"}
          bg="white"
          position="relative"
          mt="12"
          px="0"
          border="1px solid #E2E8F0;"
          box-shadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);"
          border-radius="4px"
        >
          <Outlet />
        </Container>
      </Box>
    </>
  );
};

export default Layout;
