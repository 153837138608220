import React, { useEffect, useState } from "react";
import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useFilters,
} from "react-table";
import {
  Table,
  Thead,
  Tr,
  Th,
  Flex,
  Tbody,
  Td,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  Heading,
  useToast,
  TableContainer,
  IconButton,
  Tfoot,
} from "@chakra-ui/react";
import {
  AssessmentModel,
  IndividualAssessmentModel,
  Respondent,
} from "../types/models";
import { UpDownArrowIcon } from "../icons/UpDOwnArrowIcon";
import { getAge, maskFnr } from "../utilities/HelperFunctions";
import "react-datepicker/dist/react-datepicker.css";
import { ConfirmModal } from "./ConfirmModal";
import {
  useDeleteIndividualAssessment,
  usePutAssessmentStatus,
} from "../api/hooks";
import { ChevronPreviousIcon } from "../icons/ChevronPreviousIcon";
import { ChevronNextIcon } from "../icons/ChevronNextIcon";
import { getStatusBadge } from "../pages/AssessmentStatus";

type PropType = {
  data: any[];
  dataDrafts: any[];
  columns: Column<any>[];
  assessments: IndividualAssessmentModel[] | undefined;
  filterStatus: any;
  filterName: string;
  refetchAssessments: any;
};

type AssessmentType = {
  name: string;
  status: string;
  statusBadge?: React.JSX.Element | undefined;
  assessment: AssessmentModel | IndividualAssessmentModel;
};

export const AssessmentStatusTable = (props: PropType) => {
  const toast = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMainAssessment, setSelectedMainAssessment] =
    useState<IndividualAssessmentModel>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showClosesModal, setShowCloseModal] = useState(false);
  const [showPlannedModal, setShowPlannedModal] = useState(false);
  const [selectedRespondentAssessment, setSelectedRespondentAssessment] =
    useState<AssessmentType>();

  let { isLoading: isLoadingDeleteIA, mutate: deleteIA } =
    useDeleteIndividualAssessment();

  let { isLoading: isUpdatingAssessment, mutate: updateAssessment } =
    usePutAssessmentStatus();

  const handleDelete = (id: string) => {
    deleteIA(id, {
      onSuccess: () => {
        setShowDeleteModal(false);
        setModalOpen(false);
        toast({
          title: "Kartleggingen er slettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        props.refetchAssessments();
      },
      onError(error: any) {
        toast({
          title: "Sletting av kartlegging feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const handleUpdate = (
    newStatus: string,
    assessment: AssessmentModel | undefined
  ) => {
    if (!assessment) return;
    assessment.assessmentStatus = newStatus;

    updateAssessment(assessment, {
      onSuccess: (data) => {
        if (newStatus == "Closed") setShowCloseModal(false);
        else setShowPlannedModal(false);
        toast({
          title: "Kartleggingen er oppdatert",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        props.refetchAssessments();
      },
      onError(error: any, variables, context) {
        toast({
          title: "Oppdatering av kartlegging feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const getRespondentAssessments = (
    respondents: Respondent[] | undefined,
    individualAssessmentId: string
  ): AssessmentType[] => {
    const assessments: AssessmentType[] = [];
    if (!respondents) return assessments;

    respondents.forEach((respondent: Respondent) => {
      if (
        !respondent ||
        (respondent.assessments.length === 0 &&
          respondent.individualAssessments.length === 0)
      )
        return;

      console.log(`Processing respondent: ${respondent.name}`);

      respondent.assessments.forEach((assessment) => {
        if (assessment.individualAssessmentId !== individualAssessmentId)
          return;

        const assessmentViewModel: AssessmentType = {
          name: respondent.name,
          status: assessment.assessmentStatus,
          statusBadge: getStatusBadge(
            assessment.assessmentStatus,
            "statusReports"
          ),
          assessment: assessment,
        };

        assessments.push(assessmentViewModel);
      });

      if (Array.isArray(respondent.individualAssessments)) {
        respondent.individualAssessments.forEach((individualAssessment) => {
          if (individualAssessment.id !== individualAssessmentId) return;

          const assessmentViewModel: AssessmentType = {
            name: respondent.name,
            status: individualAssessment.assessmentStatus,
            statusBadge: getStatusBadge(
              individualAssessment.assessmentStatus,
              "statusReports"
            ),
            assessment: individualAssessment,
          };

          assessments.push(assessmentViewModel);
        });
      } else {
        console.log(
          `individualAssessments is not an array: ${JSON.stringify(
            respondent.individualAssessments
          )}`
        );
      }
    });

    return assessments;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state,
    setPageSize,
    setFilter,
  } = useTable<any>(
    {
      data: props.data,
      columns: props.columns,
      defaultColumn: { Filter: () => null },
      initialState: {
        hiddenColumns: ["SSN", "statusText", "statusMainText"],
        filters: [],
        pageSize: 5,
      },
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps: getTablePropsDrafts,
    getTableBodyProps: getTableBodyPropsDrafts,
    headerGroups: headerGroupsDrafts,
    prepareRow: prepareRowDrafts,
    page: pageDrafts,

    setFilter: setFilterDrafts,
  } = useTable<any>(
    {
      data: props.dataDrafts,
      columns: props.columns,
      defaultColumn: { Filter: () => null },
      initialState: {
        hiddenColumns: ["SSN", "statusText", "statusMainText"],
        filters: [],
        pageSize: 20,
      },
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (props.filterStatus) {
      //setFilter("statusText", props.filterStatus);
      gotoPage(0);
      setFilter("statusMainText", props.filterStatus);
    } else {
      //setFilter("statusText", undefined);
      setFilter("statusMainText", undefined);
    }
  }, [props.filterStatus, setFilter]);

  useEffect(() => {
    if (props.filterName) {
      if (!isNaN(Number(props.filterName))) {
        gotoPage(0);
        setFilter("SSN", props.filterName);
        setFilter("Navn", undefined);
      } else {
        gotoPage(0);
        setFilter("Navn", props.filterName);
        setFilter("SSN", undefined);
      }
    } else {
      setFilter("Navn", undefined);
      setFilter("SSN", undefined);
    }
  }, [props.filterName, setFilter]);

  return (
    <>
      <Heading size={"lg"} mt={"16px"}>
        Kartlegginger
      </Heading>
      <Table margin="16px 0 0 0" {...getTableProps()}>
        <Thead backgroundColor={"#DFEEF9"}>
          {headerGroups.map((headerGroup) => {
            return (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        <Flex justifyContent="start">
                          {column.render("Header")}

                          {column.canSort && (
                            <Flex ml="5px">
                              <UpDownArrowIcon />
                            </Flex>
                          )}
                        </Flex>
                      }
                    </Th>
                  );
                })}
                <Th />
                <Th />
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            if (row.original.statusMainText !== "Draft")
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        <Text fontSize={"smaller"}>{cell.render("Cell")}</Text>
                      </Td>
                    );
                  })}
                  <Td>
                    {props.assessments?.find((d) => d.id === row.original.id)
                      ?.assessmentStatus !== "Journaled" && (
                      <Button
                        size={"sm"}
                        colorScheme="blue"
                        onClick={() => {
                          const selectedAssessmentTemp =
                            props.assessments?.find(
                              (d) => d.id === row.original.id
                            );
                          setSelectedMainAssessment(selectedAssessmentTemp);
                          if (selectedAssessmentTemp) {
                            setStartDate(
                              new Date(selectedAssessmentTemp.assessmentDate)
                            );
                          }

                          setModalOpen(true);
                        }}
                      >
                        Vis kartlegging
                      </Button>
                    )}
                  </Td>
                </Tr>
              );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={1000}>
              <Flex justifyContent={"space-between"}>
                <Flex
                  width={"50%"}
                  alignItems="center"
                  justifyContent={"start"}
                >
                  {/* <Text mr={"10px"} fontSize="xs">
                    Viser rader per side
                  </Text>
                  <Select
                    size={"xs"}
                    width="15%"
                    minWidth={"15%"}
                    defaultValue={5}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </Select> */}
                </Flex>
                <Flex alignItems="center" justifyContent={"end"}>
                  {page && page[0] && (
                    <Text fontSize="smaller">Side {state.pageIndex + 1}</Text>
                  )}
                  <Text color="#6E6E6E" paddingRight="20px" fontSize="smaller">
                    &nbsp;av&nbsp;{pageCount}
                  </Text>

                  <IconButton
                    size="sm"
                    variant="iconButton"
                    aria-label="Forrige side"
                    icon={<ChevronPreviousIcon />}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <IconButton
                    ml={"20px"}
                    size="sm"
                    variant="iconButton"
                    aria-label="Neste side"
                    icon={<ChevronNextIcon />}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Flex>
              </Flex>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
      <Heading size={"lg"} mt={"16px"}>
        Utkast
      </Heading>
      <Table margin="16px 0 0 0" {...getTablePropsDrafts()}>
        <Thead backgroundColor={"#DFEEF9"}>
          {headerGroupsDrafts.map((headerGroup) => {
            return (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {
                        <Flex justifyContent="start">
                          {column.render("Header")}

                          {column.canSort && (
                            <Flex ml="5px">
                              <UpDownArrowIcon />
                            </Flex>
                          )}
                        </Flex>
                      }
                    </Th>
                  );
                })}
                <Th />
                <Th />
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyPropsDrafts()}>
          {pageDrafts?.map((row) => {
            prepareRowDrafts(row);
            if (row.original.statusMainText === "Draft")
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        <Text fontSize={"smaller"}>{cell.render("Cell")}</Text>
                      </Td>
                    );
                  })}
                  <Td>
                    {props.assessments?.find((d) => d.id === row.original.id)
                      ?.assessmentStatus == "Draft" && (
                      <Button
                        size={"sm"}
                        colorScheme="red"
                        onClick={() => {
                          const selectedAssessmentTemp =
                            props.assessments?.find(
                              (d) => d.id === row.original.id
                            );
                          setSelectedMainAssessment(selectedAssessmentTemp);
                          setShowDeleteModal(true);
                        }}
                      >
                        Slett
                      </Button>
                    )}
                  </Td>
                </Tr>
              );
          })}
        </Tbody>
      </Table>
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"50%"}>
          <ModalHeader>Individuell kartlegging</ModalHeader>
          <ModalCloseButton onClick={() => onclose} />
          <ModalBody>
            <Box>
              {selectedMainAssessment &&
              selectedMainAssessment.children &&
              selectedMainAssessment.children[0]
                ? `${selectedMainAssessment.name}, ${getAge(
                    new Date(selectedMainAssessment.children[0].birthDate)
                  )} (f.nr. ${maskFnr(
                    selectedMainAssessment.children[0].nationalIdNumber
                  )})`
                : "Informasjon mangler"}
            </Box>
            <TableContainer>
              <Table>
                <Thead>
                  <Heading size={"lg"} mt={"16px"}>
                    Rapporter
                  </Heading>
                </Thead>
                <Tbody>
                  {getRespondentAssessments(
                    selectedMainAssessment?.respondents,
                    selectedMainAssessment?.id ?? ""
                  ).map((assessment: AssessmentType) => {
                    return (
                      <Tr>
                        <Td p={"16px 8px"}>{assessment.name}</Td>
                        <Td p={"16px 8px"}> {assessment.statusBadge}</Td>
                        {assessment.status == "Planned" && (
                          <Button
                            mt={"8px"}
                            size={"sm"}
                            colorScheme="blue"
                            onClick={() => {
                              setSelectedRespondentAssessment(assessment);
                              setShowCloseModal(true);
                            }}
                          >
                            Avbryt journalføring
                          </Button>
                        )}
                        {(assessment.status == "Sent" ||
                          assessment.status == "Closed") && (
                          <Button
                            mt={"8px"}
                            ml={"5px"}
                            size={"sm"}
                            colorScheme="blue"
                            onClick={() => {
                              setSelectedRespondentAssessment(assessment);
                              setShowPlannedModal(true);
                            }}
                          >
                            Sett til Planlagt
                          </Button>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"blue"}
              variant="outline"
              ml={3}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Lukk
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmModal
        show={showDeleteModal}
        onClose={setShowDeleteModal}
        closeButtonText="Avbryt"
        text={`Er du sikker på at du vil slette kartleggingen?`}
        onSubmit={() => handleDelete(selectedMainAssessment?.id || "")}
        submitButtonText="Slett"
      />
      <ConfirmModal
        show={showClosesModal}
        onClose={setShowCloseModal}
        closeButtonText="Lukk"
        text={`Er du sikker på at du vil avbryte journalføringen?`}
        onSubmit={() => {
          handleUpdate(
            "Closed",
            selectedRespondentAssessment?.assessment as
              | AssessmentModel
              | undefined
          );
        }}
        submitButtonText="Oppdater"
      />
      <ConfirmModal
        show={showPlannedModal}
        onClose={setShowPlannedModal}
        closeButtonText="Lukk"
        text={`Kartleggingen vil settes til status "Planlagt" og kan sendes på nytt.`}
        onSubmit={() => {
          handleUpdate(
            "Planned",
            selectedRespondentAssessment?.assessment as
              | AssessmentModel
              | undefined
          );
        }}
        submitButtonText="Oppdater"
      />
    </>
  );
};
