import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useUserInfo, useUser } from "../api/hooks";

function AdminRoute() {
  const { data: userInfo } = useUserInfo();
  const { data: user } = useUser();
  const isAdmin = user?.userRoleType == "SystemAdmin";
  const toast = useToast();

  useEffect(() => {
    if (!!!userInfo?.isAuthenticated || !!!isAdmin)
      toast({
        title: "Du må logge inn",
        description: "Siden krever at du er innlogget som administrator",
        status: "info",
        isClosable: true,
        position: "top",
      });
  }, []);

  return (
    <>
      {userInfo?.isAuthenticated && isAdmin ? (
        <Outlet />
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
}
export default AdminRoute;
