import * as React from 'react';
import * as Chakra from '@chakra-ui/react';

export const StandardSpinner = () => {
    return (
        <Chakra.Box my={4}>
            <Chakra.Spinner
                thickness='3px'
                size='lg'
                color='blue.500'
                mx={4}
                label={'loading'} 
            />
        </Chakra.Box>
    );
};

export default StandardSpinner;
