import React, { useState } from 'react';
import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Button,
  Link,
  Box,
} from '@chakra-ui/react';

import { useLogin, useLogout, useUserInfo } from '../api/hooks';

export const Home = () => {
  const [click, setClick] = useState(false);
  const [loggedout, setLoggedout] = useState(false);
  const { data: userInfo } = useUserInfo();
  const login = useLogin(click);
  const logout = useLogout(loggedout);

  return (
    <Box px="24">
      <Heading as="h1" size="xl" mb="8" mt="16">
        Hei og velkommen til Godt Begynt!
      </Heading>
      <Text fontSize="22px" mb="4">
        Her kan du:
      </Text>
      <UnorderedList mb="6">
        <ListItem>Sette i gang individuell kartlegging.</ListItem>
        <ListItem>Sette i gang kartlegging for skoleklasser.</ListItem>
        <ListItem>
          Få en oversikt over pågående kartlegginger og se om skjemaer er
          besvart.
        </ListItem>
        <ListItem>
          Registrere høyde, vekt og oppfølgingsgruppe per barn.
        </ListItem>
      </UnorderedList>
      {!!!userInfo?.isAuthenticated ? (
        <Button
          colorScheme="blue"
          mb="28"
          fontSize="sm"
          fontWeight="bold"
          px="6"
          height="8"
          onClick={() => setClick(true)}
        >
          Logg inn
        </Button>
      ) : (
        <Button
          colorScheme="blue"
          mb="28"
          fontSize="sm"
          fontWeight="bold"
          px="6"
          height="8"
          onClick={() => setLoggedout(true)}
        >
          Logg ut
        </Button>
      )}
      <Link
        variant={['base']}
        position="absolute"
        right="84px"
        bottom="56px"
        href="https://www.godtbegynt.no"
        isExternal
      >
        Les mer om Godt Begynt her
      </Link>
    </Box>
  );
};
