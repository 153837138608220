import { env } from "../env";
import {
  IndividualAssessmentModel,
  IndividualAssessmentCreateModel,
  AssessmentModel,
} from "../types/models";

const baseUrl = env.REACT_APP_BASE_URL || "";

export const getAllAssessments = async (): Promise<
  IndividualAssessmentModel[]
> => {
  const response = await fetch(`${baseUrl}/api/assessmentstatus/all`, {
    credentials: "include",
    headers: { Accept: "application/json" },
  });

  if (!response.ok) throw new Error(await response.text());

  const individualassessments = await response.json();

  return individualassessments;
};

export const updateAssessmentStatus = async (
  assessment: AssessmentModel | undefined
): Promise<any> => {
  const response = await fetch(
    `${baseUrl}/internal/assessmentcontrol/assessmentreport`,
    {
      method: "put",
      body: JSON.stringify(assessment),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const assessmentResponse = await response.json();

  return assessmentResponse;
};

export default {
  getAllAssessments,
  updateAssessmentStatus,
};
