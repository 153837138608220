import React from "react";
import { ReactElement } from "react";

type PropType = {
  size?: string;
};

/**
 * RemoveIcon Two sizes available, 24 or 32 px
 * @param size String md=32px default is 24px
 * @returns returns icon with given or default size
 */
function RemoveIcon(props: PropType): ReactElement {
  switch (props.size) {
    case "md":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="6" fill="#DFEEF9" />
          <path
            d="M9.92857 22.2222C9.92857 23.2 10.7643 24 11.7857 24H19.2143C20.2357 24 21.0714 23.2 21.0714 22.2222V11.5556H9.92857V22.2222ZM11.7857 13.3333H19.2143V22.2222H11.7857V13.3333ZM18.75 8.88889L17.8214 8H13.1786L12.25 8.88889H9V10.6667H22V8.88889H18.75Z"
            fill="#01459E"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="24"
          height="24"
          viewBox={`0 0 24 24`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="6" fill="#DFEEF9" />
          <path
            d="M7.71429 16.6667C7.71429 17.4 8.35714 18 9.14286 18H14.8571C15.6429 18 16.2857 17.4 16.2857 16.6667V8.66667H7.71429V16.6667ZM9.14286 10H14.8571V16.6667H9.14286V10ZM14.5 6.66667L13.7857 6H10.2143L9.5 6.66667H7V8H17V6.66667H14.5Z"
            fill="#01459E"
          />
        </svg>
      );
  }
}

export { RemoveIcon };
