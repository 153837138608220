import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({

    styles: {
        global: {
            body: {
                bg: '#f7fafc'
            },
            header: {
                bg: 'white'
            },
            a: {
                textDecoration: 'underline',
            }
        },
    },

    colors: {
        // Might want to declare colors like this, personally i think its easier to read, and it fits the schematics
        GB: {
            LightBlue: '#DFEEF9',
            Blue: '#01459E',
            LightGrey: '#F7FAFC',
            DarkGrey: '#4A4A4A',
        },
        blue:{
            200: '#DFEEF9',
            500: '#01459E'
        },

        grey:{
            500: '#4A4A4A'
        }
    },

    fonts:{
        heading: `'Lato', sans-serif`,
        body: `'Lato', sans-serif`
    },

    components: {
        Link:{
            variants:{
                base:{
                    textDecoration: 'underline',
                    color: 'GB.Blue'
                },
                nav:{
                    color: 'GB.DarkGrey',
                    _isActive:{
                        color: 'GB.Blue'
                    },
                    _hover:{
                        textDecoration: 'none'
                    }
                }
            }
        }
    }

})