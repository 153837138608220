import { env } from '../env';
import { RespondentRoleAssessmentPlan } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getRespondentRoleAssessmentPlan = async (
  id: string
): Promise<RespondentRoleAssessmentPlan> => {
  const response = await fetch(
    `${baseUrl}/api/admin/respondentroleassessmentplan/${id}`,
    {
      credentials: 'include',
      headers: { Accept: 'application/json' },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const respondAssessmentPlan = await response.json();

  return respondAssessmentPlan;
};

export const getAllRespondentRoleAssessmentPlan = async (
  HealtCheckTypeId: string
): Promise<RespondentRoleAssessmentPlan[]> => {
  const response = await fetch(
    `${baseUrl}/api/admin/respondentroleassessmentplan/all/${HealtCheckTypeId}`,
    {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const respondAssessmentPlan = await response.json();

  return respondAssessmentPlan;
};

export const postRespondentRoleAssessmentPlan = async (
  respondentRole: RespondentRoleAssessmentPlan
): Promise<any> => {
  const response = await fetch(
    `${baseUrl}/api/admin/respondentroleassessmentplan`,
    {
      method:
        respondentRole.id === '00000000-0000-0000-0000-000000000000'
          ? 'post'
          : 'put',
      body: JSON.stringify(respondentRole),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const respondentRoleAssesmentPlan = await response.json();

  return respondentRoleAssesmentPlan;
};

export const deleteRespondentRoleAssessmentPlan = async (
  respondentRoleAssessmentPlanId: string
): Promise<void> => {
  const response = await fetch(
    `${baseUrl}/api/admin/respondentroleassessmentplan?id=${respondentRoleAssessmentPlanId}`,
    {
      method: 'delete',
      credentials: 'include',
    }
  );

  if (!response.ok) throw new Error(await response.text());
};

export default {
  getRespondentRoleAssessmentPlan,
  getAllRespondentRoleAssessmentPlan,
  deleteRespondentRoleAssessmentPlan,
};
