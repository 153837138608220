import React from "react";
import {
    Center,
    Image,
    Flex,
    Spacer,
    Text,
    Button,
    Link,
} from "@chakra-ui/react";

export const Header = () => {
    var loggedIn = false;

    return (
        <header>
            <Center>
                <Flex w="1024px" m="4">
                    <Link href="/">
                        <Image src={process.env.PUBLIC_URL + "/GB-logo.svg"} />
                    </Link>
                    <Spacer />

                    {loggedIn ? <Text p="2">Hei, Navn</Text> : ""}
                    {loggedIn ? (
                        <Button colorScheme="blue" variant="outline">
                            Logg ut
                        </Button>
                    ) : (
                        ""
                    )}
                </Flex>
            </Center>
        </header>
    );
};
