import React from "react";
import { Heading, Select } from "@chakra-ui/react";

type RoleChooserProps = {
    setSelectedRole: Function;
    disabled?: boolean;
    value?: string;
};

function RoleChooser(props: RoleChooserProps) {
    return (
        <>
            <Heading size="xs" mt={5} mb={1}>
                Velg rolle *
            </Heading>
            <Select
                disabled={props.disabled ?? false}
                placeholder="Velg rolle"
                size="sm"
                width="40%"
                onChange={(e) => props.setSelectedRole(e.target.value)}
                value={props.value ?? ""}
            >
                <option value="parent">Foresatt</option>
                <option value="teacher">Lærer</option>
                <option value="kindergartenteacher">Barnehagelærer</option>
            </Select>
        </>
    );
}

export default RoleChooser;
