import React, { useState } from "react";
import {
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Button,
  FormControl,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

type PropTypes = {
  onSubmit: (searchString: string) => void;
  heading?: string;
  placeholder?: string;
};

const SearchForm = (props: PropTypes) => {
  const { heading, onSubmit, placeholder } = props;

  const [input, setInput] = useState("");

  const handleSubmit = React.useCallback(() => {
    onSubmit(input.trim());
    setInput("");
  }, [input]);

  return (
    <>
      {heading && (
        <Heading size="md" mb="3" fontWeight="400">
          {heading} <SearchIcon />
        </Heading>
      )}
      <FormControl>
        <InputGroup maxW="464px" mb="8">
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            placeholder={placeholder}
          />
          <InputRightAddon
            p="0"
            children={
              <Button
                id="submitButton"
                onClick={handleSubmit}
                colorScheme="blue"
                size="md"
                borderLeftRadius={0}
                fontWeight="normal"
              >
                Søk
              </Button>
            }
          />
        </InputGroup>
      </FormControl>
    </>
  );
};

export default SearchForm;
