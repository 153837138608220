import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import IndividualAssessment from "./pages/IndividualAssessment";
import SchoolClassAssessment from "./pages/SchoolClassAssessment";
import Layout from "./pages/Layout";
import NotFound from "./pages/NotFound";
import HeightAndWeightAssessment from "./pages/HeightWeightAssessment";
import { useAuth } from "react-oidc-context";
import OidcRedirect from "./pages/OidcRedirect";
import { Spinner } from "@chakra-ui/react";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import Administration from "./pages/Administration";
import AssessmentStatus from "./pages/AssessmentStatus";

function App() {
  const auth = useAuth();

  return !!!auth.isLoading ? (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/oauth-redirect" element={<OidcRedirect />} />

        <Route path="/IndividualMapping" element={<PrivateRoute />}>
          <Route path="/IndividualMapping" element={<IndividualAssessment />} />
        </Route>

        <Route path="/SchoolClassAssessment" element={<PrivateRoute />}>
          <Route
            path="/SchoolClassAssessment"
            element={<SchoolClassAssessment />}
          />
        </Route>
        <Route path="/HeightAndWeightAssessment" element={<PrivateRoute />}>
          <Route
            path="/HeightAndWeightAssessment"
            element={<HeightAndWeightAssessment />}
          />
        </Route>
        <Route path="/Administration" element={<AdminRoute />}>
          <Route path="/Administration" element={<Administration />} />
        </Route>
        <Route path="/AssessmentStatus" element={<PrivateRoute />}>
          <Route path="/AssessmentStatus" element={<AssessmentStatus />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  ) : (
    <Spinner />
  );
}
export default App;
