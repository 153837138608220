import { DeleteIcon, PlusSquareIcon, QuestionIcon } from "@chakra-ui/icons";
import {
  Container,
  Heading,
  VStack,
  Icon,
  Spinner,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { WarningIcon } from "@chakra-ui/icons";
import AssesmentDropdown from "../components/AssessmentDropdown";
import FeatureDisabled from "../components/FeatureDisabled";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { getAllSchools } from "../api/bffSchoolApi";
import { getAllSchoolClasses } from "../api/bffSchoolClassApi";
import { School, SchoolClass } from "../types/models";
import { Navigate } from "react-router-dom";
import { env } from "../env";
import { useUserInfo } from "../api/hooks";

enum FetchStatus {
  uninitialized,
  initialized,
  completed,
  failed,
}

const defaultDateFormat = "DD/MM/YYYY";

const SchoolClassAssessment = () => {
  const { data: userInfo } = useUserInfo();

  const [fetchSchoolsStatus, setFetchSchoolsStatus] = useState<FetchStatus>(
    FetchStatus.uninitialized
  );
  const [fetchClassesStatus, setFetchClassesStatus] = useState<FetchStatus>(
    FetchStatus.uninitialized
  );
  const [isSchoolAndClassConfirmed, setSchoolAndClassConfirmed] =
    useState<boolean>(false);

  const [schoolList, setSchoolList] = useState<Array<School>>([]);
  const [classList, setClassList] = useState<Array<SchoolClass>>([]);

  const [chosenSchool, setChosenSchool] = useState<School | undefined>(
    undefined
  );
  const [chosenClass, setChosenClass] = useState<SchoolClass | undefined>(
    undefined
  );
  const [kartleggingsDatoer, setKartleggingsDatoer] = useState<
    Array<DateObject> | DateObject | null
  >([]);

  useEffect(() => {
    setFetchSchoolsStatus(FetchStatus.initialized);

    getAllSchools()
      .then((schools) => {
        setSchoolList(schools);
        setFetchSchoolsStatus(FetchStatus.completed);
      })
      .catch((error) => {
        setFetchSchoolsStatus(FetchStatus.failed);
      });
  }, []);

  useEffect(() => {
    setFetchClassesStatus(FetchStatus.initialized);

    getAllSchoolClasses()
      .then((classes) => {
        setClassList(classes);
        setFetchClassesStatus(FetchStatus.completed);
      })
      .catch((error) => {
        setFetchClassesStatus(FetchStatus.failed);
      });
  }, []);

  function renderSchoolAndClassForm() {
    if (!!!userInfo?.isAuthenticated) return <Navigate to="/" />;
    if (env.REACT_APP_FEATURE_SCHOOLCLASS_ASSESSMENT === "false")
      return <FeatureDisabled />;
    return (
      <VStack spacing="16px" align={"flex-start"}>
        <Heading size="md">Finn Klasse</Heading>
        <Text>
          Hvis du allerede har opprettet eller importert en klasse, kan du finne
          den her.
        </Text>
        <Text>Velg Skole</Text>
        <AssesmentDropdown
          onChange={(option) => {
            const newSchool = schoolList.find(
              (school) => school.id === option.id
            );
            if (newSchool?.id !== chosenSchool?.id) {
              setChosenClass(undefined);
            }
            setChosenSchool(newSchool);
          }}
          options={schoolList}
          chosenOption={
            !!chosenSchool
              ? { id: chosenSchool.id, name: chosenSchool.name }
              : undefined
          }
        />

        <Text>Velg Klasse</Text>
        <AssesmentDropdown
          chosenOption={
            !!chosenClass
              ? { id: chosenClass.id, name: chosenClass.name }
              : undefined
          }
          options={classList.filter(
            (schoolClass) => schoolClass.schoolId === chosenSchool?.id
          )}
          onChange={(option) => {
            const newClass = classList.find(
              (schoolClass) => schoolClass.id === option.id
            );
            setChosenClass(newClass);
          }}
          isDisabled={!chosenSchool}
        ></AssesmentDropdown>
        <Button
          disabled={!chosenSchool || !chosenClass}
          onClick={() => {
            setSchoolAndClassConfirmed(true);
          }}
          minW={"10em"}
          colorScheme={"blue"}
        >
          Gå videre
        </Button>
        {isSchoolAndClassConfirmed ? renderAssessmentDetails() : undefined}

        <Text>
          Finner du ikke klassen du leter etter? Da kan du opprette den her:
        </Text>
        <Button disabled={!chosenSchool} variant="outline" color="blue">
          Opprett ny klasse
        </Button>
      </VStack>
    );
  }
  function renderError() {
    if (env.REACT_APP_FEATURE_SCHOOLCLASS_ASSESSMENT === "false")
      return <FeatureDisabled />;
    const feilmelding = `Gjelder henting av 
    ${fetchClassesStatus === FetchStatus.failed ? "skoleklasser" : ""} 
    ${fetchSchoolsStatus === FetchStatus.failed ? "skoler" : ""} `; // TODO: gi en god tilbakemelding her eller håndtere det globalt allerede ved feiling ved fetch
    return (
      <>
        <Text>
          <Icon as={WarningIcon} />
          Dette gikk skikkelig dårlig
        </Text>
        <Text>{feilmelding}</Text>
      </>
    );
  }

  function renderSpinner() {
    return (
      <Container centerContent={true}>
        <Spinner
          thickness="6px"
          speed="1.0s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Container>
    );
  }

  function renderAssessmentDetails() {
    return (
      <>
        <Text>Dato(er) for kartlegging</Text>
        <DatePicker
          render={(selectedDates: any, openCalendar: () => void) => {
            if (!selectedDates || selectedDates.length === 0) {
              return (
                <input
                  onClick={(e) => {
                    e.preventDefault();
                    openCalendar();
                  }}
                  type="date"
                />
              );
            } else {
              return (
                <>
                  <ul>
                    {selectedDates.map((date: string) => (
                      <li key={date}>
                        {date}{" "}
                        <DeleteIcon
                        // onClick={() => {
                        //   setKartleggingsDatoer((existingDates) => {
                        //     if (
                        //       !!existingDates &&
                        //       Array.isArray(existingDates)
                        //     ) {
                        //       return existingDates?.filter(
                        //         (d) => d.format(defaultDateFormat) !== date
                        //       );
                        //     } else {
                        //       setKartleggingsDatoer([]);
                        //       return [];
                        //     }
                        //   });
                        // }}
                        />
                      </li>
                    ))}
                  </ul>
                  <Text onClick={openCalendar}>
                    Legg til ny dato <PlusSquareIcon />
                  </Text>
                </>
              );
            }
          }}
          multiple
          value={kartleggingsDatoer}
          onChange={(datoListe: any) => {
            setKartleggingsDatoer(datoListe);
          }}
          format={defaultDateFormat}
          plugins={[<DatePanel />]}
        />
      </>
    );
  }

  function renderBody() {
    switch (fetchSchoolsStatus) {
      case FetchStatus.failed:
        return renderError();
      case FetchStatus.completed:
        return renderSchoolAndClassForm();
      default:
        return renderSpinner();
    }
  }

  return (
    <VStack spacing="24px" align={"flex-start"} p="9">
      <Heading size="xl" mb="8">
        Kartlegging skoleklasse <QuestionIcon color="blue.500" ml={2} pb={1} />
      </Heading>

      {renderBody()}
    </VStack>
  );
};

export default SchoolClassAssessment;
