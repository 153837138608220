import { useToast } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as React from "react";
import bffApi from "./api/bffApiIndex";
import { AuthContextProvider } from "./context/authContext";

export const ApiContext = React.createContext(bffApi);

const QueryClientProviderContext: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const toast = useToast();

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            retry: false,
            staleTime: 3600000, // 1 hour
          },
        },
      }),
    [toast]
  );
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export const AppContextProvider: React.FC<{
  api?: typeof bffApi;
  children: React.ReactNode;
}> = ({ api = bffApi, children }) => {
  return (
    <QueryClientProviderContext>
      <AuthContextProvider>
        <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
      </AuthContextProvider>
    </QueryClientProviderContext>
  );
};
