import React from "react";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { AlertIcon } from "../icons/AlertIcon";

type PropTypes = {
  show: boolean;
  onClose: Function;
  onSubmit: Function;
  closeButtonText: string;
  submitButtonText: string;
  text: string;
};

export function ConfirmModal(props: PropTypes) {
  return (
    <>
      <Modal isOpen={props.show} onClose={() => props.onClose(false)}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Box display={"inline-flex"}>
              <AlertIcon />{" "}
              <Heading size="md" ml={2} color="#4A4A4A">
                Er du sikker?
              </Heading>
            </Box>
          </ModalHeader>

          <ModalBody>{props.text}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"blue"}
              variant="outline"
              ml={3}
              onClick={() => {
                props.onClose(false);
              }}
            >
              {props.closeButtonText}
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                props.onSubmit();
                props.onClose(false);
              }}
            >
              {props.submitButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
