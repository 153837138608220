import { RespondentRoleTypes } from "../types/models";

/** Returns dateonly as string (yyyy-mm-dd) */
export const formatDateOnly = (date: Date) => {
  function pad(s: number) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(date);
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
};

export function getAge(birthdate: Date) {
  if (!birthdate) return;
  return getAgeNumber(birthdate) + " år";
}

export function getAgeNumber(birthdate: Date) {
  if (!birthdate) return;
  var month_diff = Date.now() - new Date(birthdate).getTime();
  var age_dt = new Date(month_diff);
  var year = age_dt.getUTCFullYear();
  var age = Math.abs(year - 1970);
  if (age == undefined) return 0;
  return age;
}

export function displayRespondentRoleNameById(respondentRoleId: string) {
  switch (respondentRoleId) {
    case RespondentRoleTypes.child.toString(): {
      return "Barnet";
    }
    case RespondentRoleTypes.parent1.toString(): {
      return "Foresatt 1";
    }
    case RespondentRoleTypes.parent2.toString(): {
      return "Foresatt 2";
    }
    case RespondentRoleTypes.teacher.toString(): {
      return "Lærer";
    }
    case RespondentRoleTypes.kindergartenteacher.toString(): {
      return "Barnehagelærer";
    }
  }
}

export function maskFnr(nationalIdNumber: string) {
  const first6 = nationalIdNumber.substring(0, 6);
  const last2 = nationalIdNumber.substring(nationalIdNumber.length - 2);

  const mask = nationalIdNumber
    .substring(6, nationalIdNumber.length - 2)
    .replace(/\d/g, "*");
  return first6 + mask + last2;
}
