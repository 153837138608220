import React from "react";
import { Input, Text } from "@chakra-ui/react";
import GenericDropdown from "./GenericDropdown";

export const AssessmentStatusFilter = (props: {
  filterName: string;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  filterStatus: any;
  setFilterStatus: React.Dispatch<any>;
}) => {
  return (
    <>
      <GenericDropdown
        heading="Status"
        placeHolder="Alle statuser"
        items={[
          { id: "draft", name: "Utkast" },
          { id: "planned", name: "Planlagt" },
          { id: "sent", name: "Sendt" },
          { id: "journaled", name: "Journalført" },
          { id: "closed", name: "Avbrutt" },
        ]}
        onChange={props.setFilterStatus}
        value={props.filterStatus}
      />
      <Text fontWeight={"bold"} mt={"1rem"}>
        Søk på barnets navn og/eller fødelsnummer
      </Text>
      <Input
        width={"50%"}
        placeholder="Søk på barnets navn og/eller fødelsnummer (11 siffer)"
        value={props.filterName}
        onChange={(e) => props.setFilterName(e.target.value)}
      />
    </>
  );
};
