import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";

interface AssessmentDropdownOption {
  name: string;
  id: string;
}

interface AssessmentDropdownProps {
  onChange: (option: AssessmentDropdownOption) => void;
  options: Array<AssessmentDropdownOption>;
  isDisabled?: boolean;
  chosenOption?: AssessmentDropdownOption;
}

const AssesmentDropdown: React.FC<AssessmentDropdownProps> = (
  props: AssessmentDropdownProps
) => {
  return (
    <Box>
      <Menu>
        <MenuButton
          disabled={props.isDisabled}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="outline"
          textAlign="left"
          minW={"250px"}
        >
          {props.chosenOption?.name ?? "Velg klasse"}
        </MenuButton>
        <MenuList>
          {props.options.map((option) => (
            <MenuItem
              margin={0}
              onClick={() => props.onChange(option)}
              key={option.id}
            >
              {option.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default AssesmentDropdown;
