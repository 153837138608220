import { env } from '../env';
import { MunicipalityModel } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllMunicipalities = async (): Promise<MunicipalityModel[]> => {
  const response = await fetch(`${baseUrl}/api/admin/municipality/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const municipalities = await response.json();

  return municipalities;
};

export const getMunicipality = async (
  id: string
): Promise<MunicipalityModel> => {
  const response = await fetch(`${baseUrl}/api/admin/municipality/${id}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const municipality = await response.json();

  return municipality;
};

export const postMunicipality = async (
  municipality: MunicipalityModel
): Promise<MunicipalityModel> => {
  const response = await fetch(`${baseUrl}/api/admin/municipality`, {
    method:
      municipality?.id === '00000000-0000-0000-0000-000000000000'
        ? 'post'
        : 'put',
    body: JSON.stringify(municipality),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const municipalityResponse = await response.json();

  return municipalityResponse;
};

export const deleteMunicipality = async (
  municipalityId: string
): Promise<string> => {
  const response = await fetch(
    `${baseUrl}/api/admin/municipality?id=${municipalityId}`,
    {
      method: 'delete',
      credentials: 'include',
    }
  );

  if (!response.ok) throw new Error(await response.text());

  return municipalityId;
};

export default {
  getAllMunicipalities,
  postMunicipality,
  deleteMunicipality,
  getMunicipality,
};
