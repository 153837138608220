import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import {
  Box,
  Button,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { RightArrowIcon } from "../icons/RigthArrowIcon";
import HealthCenterList from "../components/HealthCenterList";
import SchoolList from "../components/SchoolList";
import SchoolClassList from "../components/SchoolClassList";
import MunicipalList from "../components/MunicipalList";
import HealthCheckTypeList from "../components/HealthCheckTypeList";
import UserList from "../components/UserList";

const selectedStyle = {
  fontWeight: "bold",
  borderBottom: "solid 3px #01459E",
  pb: "2px",
};

const tabItemStyle = {
  paddingLeft: "0px",
  paddingRight: "0px",
};

const Administration = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = [
    "Brukere",
    // "Helsestasjon",
    "Type kontroll",
    "Skoler",
    // "Skoleklasse",
    // "Kommune",
  ];

  return (
    <>
      <Box p="9" minW="1280px">
        <Heading size="xl" mb="64px">
          Administrasjon
        </Heading>
        <Tabs
          orientation="vertical"
          variant={"unstyled"}
          color={"#4A4A4A"}
          onChange={(index) => {
            setSelectedTabIndex(index);
          }}
        >
          <TabList
            alignItems={"start"}
            mr={"24px"}
            maxWidth={"150px"}
            width={"150px"}
          >
            {tabs.map((tab, index) => {
              return (
                <Tab key={index} _selected={selectedStyle} style={tabItemStyle}>
                  {tab}{" "}
                  {selectedTabIndex === index && (
                    <div style={{ marginLeft: "10px" }}>
                      <RightArrowIcon />
                    </div>
                  )}
                </Tab>
              );
            })}
          </TabList>

          <TabPanels
            borderLeft={"solid"}
            borderLeftColor={"blackAlpha.500"}
            borderLeftWidth={2}
            paddingLeft={"16px"}
          >
            <TabPanel pt={"0px"}>
              <UserList />
            </TabPanel>
            {/* <TabPanel pt={'0px'}>
              <HealthCenterList />
            </TabPanel> */}
            <TabPanel pt={"0px"}>
              <HealthCheckTypeList />
            </TabPanel>
            <TabPanel pt={"0px"}>
              <SchoolList />
            </TabPanel>
            {/* <TabPanel pt={'0px'}>
              <SchoolClassList />
            </TabPanel> */}
            {/* <TabPanel pt={"0px"}>
              <MunicipalList />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default Administration;
