import { env } from '../env';
import { Respondent } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getRespondents = async (search: string): Promise<Respondent[]> => {
  const response = await fetch(
    `${baseUrl}/api/respondent/search/api/respondent/search/?queryString=${search}`,
    {
      credentials: 'include',
      headers: { Accept: 'application/json' },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const respondent = await response.json();

  return respondent;
};

export const getRespondentsByNationalId = async (
  search: string
): Promise<Respondent> => {
  const response = await fetch(
    `${baseUrl}/api/respondent/search/api/respondent/search/${search}`,
    {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const respondent = await response.json();

  return respondent;
};

export const getRespondentsById = async (
  search: string
): Promise<Respondent> => {
  const response = await fetch(
    `${baseUrl}/api/respondent/search/api/respondent/${search}`,
    {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const respondent = await response.json();

  return respondent;
};

export default {
  getRespondents,
  getRespondentsById,
  getRespondentsByNationalId,
};
