import {
  Table,
  Thead,
  Tr,
  Th,
  Flex,
  Tbody,
  Td,
  IconButton,
  Tfoot,
  Text,
  Box,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { ChevronNextIcon } from "../icons/ChevronNextIcon";
import { ChevronPreviousIcon } from "../icons/ChevronPreviousIcon";
import { EditIcon } from "../icons/EditIcon";
import { RemoveIcon } from "../icons/RemoveIcon";
import { UpDownArrowIcon } from "../icons/UpDOwnArrowIcon";

type PropType = {
  data: any[];
  columns: Column<any>[];
  onChange: Function;
  onDelete: Function;
  canEdit?: boolean;
};

function AdminTable(props: PropType) {
  const [currentPage, setCurrentPage] = useState(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state,
    setPageSize,
  } = useTable<any>(
    {
      data: props.data,
      columns: props.columns,
      initialState: { pageSize: 5 },
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <Table margin="40px 0 0 0" {...getTableProps()}>
      <Thead backgroundColor={"#DFEEF9"}>
        {headerGroups.map((headerGroup) => {
          return (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {
                      <Flex justifyContent="start">
                        {column.render("Header")}
                        {column.canSort && (
                          <Flex ml="5px">
                            <UpDownArrowIcon />
                          </Flex>
                        )}
                      </Flex>
                    }
                  </Th>
                );
              })}
              <Th />
              <Th />
            </Tr>
          );
        })}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page?.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Td {...cell.getCellProps()}>
                    <Text fontSize={"smaller"}>{cell.render("Cell")}</Text>
                  </Td>
                );
              })}
              {props.canEdit ?? (
                <Td width="120">
                  <Flex alignItems="center" justify={"end"}>
                    <Text fontSize={"smaller"}>Endre </Text>
                    <Flex ml="8px">
                      <IconButton
                        size="sm"
                        variant="iconButton"
                        aria-label="Endre"
                        icon={<EditIcon size="md" />}
                        onClick={() => {
                          props.onChange(row.original.id);
                        }}
                      />
                    </Flex>
                  </Flex>
                </Td>
              )}
              <Td width="120">
                <Flex alignItems="center" justify={"end"}>
                  <Text fontSize={"smaller"}>Slett</Text>
                  <Flex ml="8px">
                    <IconButton
                      size="sm"
                      variant="iconButton"
                      aria-label="Slett"
                      icon={<RemoveIcon size="md" />}
                      onClick={() => {
                        props.onDelete(row.original.id);
                      }}
                    />
                  </Flex>
                </Flex>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={1000}>
            <Flex justifyContent={"space-between"}>
              <Flex width={"50%"} alignItems="center" justifyContent={"start"}>
                <Text mr={"10px"} fontSize="xs">
                  Viser rader per side
                </Text>
                <Select
                  size={"xs"}
                  width="15%"
                  minWidth={"15%"}
                  defaultValue={5}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Select>
              </Flex>
              <Flex alignItems="center" justifyContent={"end"}>
                {page && page[0] && (
                  <Text fontSize="smaller">Side {state.pageIndex + 1}</Text>
                )}
                <Text color="#6E6E6E" paddingRight="20px" fontSize="smaller">
                  &nbsp;av&nbsp;{pageCount}
                </Text>

                <IconButton
                  size="sm"
                  variant="iconButton"
                  aria-label="Forrige side"
                  icon={<ChevronPreviousIcon />}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <IconButton
                  ml={"20px"}
                  size="sm"
                  variant="iconButton"
                  aria-label="Neste side"
                  icon={<ChevronNextIcon />}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Flex>
            </Flex>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
}
export default AdminTable;
