import React from "react";
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { AlertIcon } from "../icons/AlertIcon";

type PropTypes = {
  show: boolean;
  onClose: Function;
  closeButtonText: string;
  text: string;
  showHeaderIcon: boolean;
};

export function InfoModal(props: PropTypes) {
  return (
    <>
      <Modal isOpen={props.show} onClose={() => props.onClose(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.showHeaderIcon ?? <AlertIcon />}</ModalHeader>
          <ModalBody>{props.text}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"blue"}
              variant="outline"
              ml={3}
              onClick={() => {
                props.onClose(false);
              }}
            >
              {props.closeButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
