import { env } from "../env";
import {
  IndividualAssessmentModel,
  IndividualAssessmentCreateModel,
} from "../types/models";

const baseUrl = env.REACT_APP_BASE_URL || "";

export const getIndividualAssessment = async (
  id: string
): Promise<IndividualAssessmentModel> => {
  const response = await fetch(`${baseUrl}/api/individualassessment/${id}`, {
    credentials: "include",
    headers: { Accept: "application/json" },
  });

  if (!response.ok) throw new Error(await response.text());

  const individualassessment = await response.json();

  return individualassessment;
};

export const getAllIndividualAssessments = async (): Promise<
  IndividualAssessmentModel[]
> => {
  const response = await fetch(`${baseUrl}/api/individualassessment/all`, {
    credentials: "include",
    headers: { Accept: "application/json" },
  });

  if (!response.ok) throw new Error(await response.text());

  const individualassessments = await response.json();

  return individualassessments;
};

export const postIndividualAssessment = async (
  individualAssessment: IndividualAssessmentCreateModel | undefined
): Promise<any> => {
  const response = await fetch(`${baseUrl}/api/individualassessment`, {
    method:
      individualAssessment?.id === "00000000-0000-0000-0000-000000000000"
        ? "post"
        : "put",
    body: JSON.stringify(individualAssessment),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const individualassessment = await response.json();

  return individualassessment;
};

export const deleteIndividualAssessment = async (
  individualAssessmentId: string
): Promise<string> => {
  const response = await fetch(
    `${baseUrl}/api/individualassessment?id=${individualAssessmentId}`,
    {
      credentials: "include",
      method: "delete",
    }
  );

  if (!response.ok) throw new Error(await response.text());

  return individualAssessmentId;
};

export default {
  getIndividualAssessment,
  getAllIndividualAssessments,
  postIndividualAssessment,
  deleteIndividualAssessment,
};
