import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { AppContextProvider } from "./Context";
import App from "./App";
import { theme } from "./theme";
import CookieConsent from "react-cookie-consent";
import { AuthProvider } from "react-oidc-context";
import { User } from "oidc-client-ts";
import { env } from "./env";

const root = createRoot(document.getElementById("root") as HTMLElement);
const authority = env.REACT_APP_OIDC_AUTHORITY || "";
const clientId = env.REACT_APP_OIDC_CLIENTID || "";
const redirectUrl = env.REACT_APP_OIDC_REDIRECT_URL || "";

const oidcConfig = {
  authority: authority,
  client_id: clientId,
  redirect_uri: redirectUrl,
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  post_logout_redirect_uri: redirectUrl,
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <AppContextProvider>
          <ChakraProvider theme={theme}>
            <App />
            <CookieConsent
              location="bottom"
              buttonText="Jeg forstår"
              cookieName="GBAppCookieConsent"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#000", fontSize: "16px" }}
              expires={90}
            >
              Denne siden benytter informasjonskapsler for å forbedre
              brukeropplevelsen.
            </CookieConsent>
          </ChakraProvider>
        </AppContextProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
