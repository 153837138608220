import React, { useState, useEffect } from "react";
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import { Respondent } from "../types/models";
import { UserIcon } from "../icons/UserIcon";
import RoleChooser from "./RoleChooser";

type PropTypes = {
  show: boolean;
  onSubmit: Function;
  onClose: Function;
  headerText: string;
  submitButtonText: string;
  abortButtonText: string;
  respondent?: Respondent;
};

function EditRespondentModal(props: PropTypes) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");

  const handleChangeNumber = (event: any) => {
    setPhoneNumber(event.target.value);
  };
  const handleChangeRole = (role: string) => {
    setRole(role);
  };

  useEffect(() => {
    setPhoneNumber(props.respondent?.phoneNumber ?? "");
    setRole(props.respondent?.respondentRoleType ?? "");
  }, [props.respondent]);

  return (
    <>
      <Modal isOpen={props.show} onClose={() => props.onClose(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div style={{ display: "inline-flex" }}>
              <UserIcon />{" "}
              <Heading size="md" ml={2} color="#4A4A4A">
                {props.respondent?.name ?? ""}
              </Heading>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Heading size="xs" mb={1}>
                Mobilnummer
              </Heading>
              <Input
                placeholder="Mobilnummer"
                width="40%"
                onChange={handleChangeNumber}
                value={phoneNumber}
              />
              {role !== "Child" && (
                <RoleChooser
                  setSelectedRole={handleChangeRole}
                  disabled={props.respondent?.isDefaultParent}
                  value={role}
                />
              )}
            </>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                if (props.respondent != undefined) {
                  props.respondent.phoneNumber = phoneNumber;
                  props.respondent.respondentRoleType = role;
                }
                props.onSubmit(props.respondent);
                props.onClose(false);
              }}
              disabled={
                phoneNumber.match("^[+0-9]{11}$|^[0-9]{8}$") ||
                phoneNumber == ""
                  ? false
                  : true
              }
            >
              {props.submitButtonText}
            </Button>
            <Button
              colorScheme={"blue"}
              variant="outline"
              ml={3}
              onClick={() => {
                props.onClose(false);
              }}
            >
              {props.abortButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditRespondentModal;
