import { env } from '../env';
import { HealthcheckType } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllHealthcheckTypes = async (): Promise<HealthcheckType[]> => {
  const response = await fetch(`${baseUrl}/api/admin/healthchecktype/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const healthchecktypes = await response.json();

  return healthchecktypes;
};

export const postHealthcheckTypes = async (
  healthCheckType: any
): Promise<any> => {
  const response = await fetch(`${baseUrl}/api/admin/healthchecktype`, {
    method:
      healthCheckType?.id === '00000000-0000-0000-0000-000000000000'
        ? 'post'
        : 'put',
    body: JSON.stringify(healthCheckType),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const healthchecktype = await response.json();

  return healthchecktype;
};

export const deleteHealthcheckTypes = async (
  healthCheckTypeId: string
): Promise<void> => {
  const response = await fetch(
    `${baseUrl}/api/admin/healthchecktype?id=${healthCheckTypeId}`,
    {
      credentials: 'include',
      method: 'delete',
    }
  );

  if (!response.ok) throw new Error(await response.text());
};

export default {
  getAllHealthcheckTypes,
  postHealthcheckTypes,
  deleteHealthcheckTypes,
};
