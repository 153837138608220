import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Input,
  propNames,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  CheckwareModel,
  HealthcheckType,
  Respondent,
  RespondentRoleAssessmentPlan,
  RespondGroupModel,
  TreatmentModel,
} from "../types/models";
import { EditIcon } from "../icons/EditIcon";
import { RemoveIcon } from "../icons/RemoveIcon";
import Select from "react-select";
import { useGetRespondentRoleAssessmentPlan } from "../api/hooks";
import { displayRespondentRoleNameById } from "../utilities/HelperFunctions";

type PropType = {
  onClose: Function;
  onSubmit: Function;
  onSubmitRole: Function;
  healthCheckType?: HealthcheckType;
  RespondentGroup?: Respondent[];
  treatmentTypes?: TreatmentModel[];
  respondentGroups?: RespondGroupModel[];
  assessmentsPlan?: CheckwareModel[];
  selectedTreatmentType?: string;
  setSelectedTreatmentType: (treatmentType: string) => void;
  selectedRespondentGroup?: string;
  setSelectedRespondentGroup: (respondGroup: string) => void;
  healthCenter: string;
  respondentRole: RespondentRoleAssessmentPlan[];
  selectedRoleAssessmentPlan?: RespondentRoleAssessmentPlan;
  submitted?: boolean;
  // TODO Få onchange funskjon til å fungere
  // onChange: Function;
  onDelete: Function;
};

const AdminCreateUpdateHealthCheckType = (props: PropType) => {
  const [healthCheckTypeName, setHealthCheckTypeName] = useState("");

  useEffect(() => {
    setHealthCheckTypeName(props.healthCheckType?.name ?? "");
    props.setSelectedTreatmentType(
      props.healthCheckType?.treatmentTypeId ?? ""
    );
    props.setSelectedRespondentGroup(
      props.healthCheckType?.respondentGroupId ?? ""
    );
  }, [props.respondentRole]);

  return (
    <>
      <Box color={"#4A4A4A"}>
        <Heading mt={"32px"} fontSize={"20px"}>
          {!!!props.healthCheckType
            ? "Legg til type kontroll"
            : "Endre type kontroll"}
        </Heading>

        <Text mt={"8px"} mb={"24px"}>
          Alle felt må fylles ut.
        </Text>
        <Text fontWeight={"bold"}>Type kontroll</Text>
        <Input
          value={healthCheckTypeName}
          placeholder="Navn på type kontroll"
          onChange={(event) => setHealthCheckTypeName(event?.target.value)}
        />
        <Text mt={"24px"} fontWeight={"bold"}>
          Behandlingstype
        </Text>
        <Select
          value={props.treatmentTypes?.find(
            (s) => s.treatmentTypeId === props.selectedTreatmentType
          )}
          isSearchable={true}
          options={props.treatmentTypes}
          getOptionValue={(options) => options.treatmentTypeId}
          getOptionLabel={(options) => options.name}
          placeholder="Søk her eller velg direkte fra listen"
          onChange={(value) => {
            if (value) props.setSelectedTreatmentType(value?.treatmentTypeId);
          }}
          noOptionsMessage={() => "Fant ingen behandlingstype"}
          isDisabled={props.submitted}
        />
        <Text mt={"24px"} fontWeight={"bold"}>
          Respondentgruppe
        </Text>
        <Select
          value={props.respondentGroups?.find(
            (s) => s.respondentGroupId === props.selectedRespondentGroup
          )}
          isSearchable={true}
          options={props.respondentGroups}
          getOptionValue={(options) => options.respondentGroupId}
          getOptionLabel={(options) => options.name}
          placeholder="Søk her eller velg direkte fra listen"
          onChange={(value) => {
            props.setSelectedRespondentGroup(value?.respondentGroupId ?? "");
          }}
          noOptionsMessage={() => "Fant ingen respondentgruppe"}
          isDisabled={props.submitted}
        />
        <Text mt="24px" fontWeight={"bold"}>
          Roller
        </Text>
        <Stack>
          {props.respondentRole?.map(
            (role: RespondentRoleAssessmentPlan, i: number) => (
              <div key={role.id}>
                <Checkbox
                  key={role.id}
                  defaultChecked
                  onChange={() => {
                    props.onDelete(role.id);
                  }}
                >
                  (
                  {displayRespondentRoleNameById(
                    role.respondentRoleId.toString()
                  )}
                  ) {role.name}
                </Checkbox>

                {/* TODO: Få onchange funcksjon til å fungere 
                {
                  <IconButton
                    border={0}
                    onClick={() => {
                      props.openModal;
                      oneRespondentRoleAssessmentPlan;
                    }}
                    ml="2"
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    aria-label="Edit"
                    icon={<EditIcon />}
                  />
                } */}

                {
                  <IconButton
                    border={0}
                    onClick={() => {
                      props.onDelete(role.id);
                    }}
                    ml="2"
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    aria-label="Delete"
                    icon={<RemoveIcon />}
                  />
                }
              </div>
            )
          )}
          <Text
            color="GB.Blue"
            cursor="pointer"
            onClick={() =>
              props.onSubmitRole({
                id:
                  props.healthCheckType?.id ??
                  "00000000-0000-0000-0000-000000000000",
                name: healthCheckTypeName,
                respondentGroupId: props.selectedRespondentGroup,
                treatmentTypeId: props.selectedTreatmentType,
                healthCenterId: props.healthCenter,
              })
            }
          >
            + Legg til rolle
          </Text>
        </Stack>
      </Box>
      <Flex
        justifyContent={"space-between"}
        mt={"24px"}
        mb={"24px"}
        width={"60%"}
      >
        <Button
          colorScheme={"blue"}
          padding={"21px 30px"}
          disabled={healthCheckTypeName === ""}
          onClick={() =>
            props.onSubmit({
              id:
                props.healthCheckType?.id ??
                "00000000-0000-0000-0000-000000000000",
              name: healthCheckTypeName,
              respondentGroupId: props.selectedRespondentGroup,
              treatmentTypeId: props.selectedTreatmentType,
              healthCenterId: props.healthCenter,
            })
          }
          mr={"10px"}
        >
          {!!!props.healthCheckType ? "Legg til" : "Oppdater"}
        </Button>
        <Button
          variant={"outline"}
          colorScheme={"blue"}
          padding={"20px 30px"}
          onClick={() => props.onClose()}
        >
          Avbryt
        </Button>
      </Flex>
    </>
  );
};

export default AdminCreateUpdateHealthCheckType;
