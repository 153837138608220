import { env } from '../env';
import { School } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllSchools = async (): Promise<School[]> => {
  const response = await fetch(`${baseUrl}/api/admin/school/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const schools = await response.json();

  return schools;
};

export const postSchool = async (school: any): Promise<School> => {
  const response = await fetch(`${baseUrl}/api/admin/school`, {
    method:
      school?.id === '00000000-0000-0000-0000-000000000000' ? 'post' : 'put',
    body: JSON.stringify(school),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const schoolResponse = await response.json();

  return schoolResponse;
};

export const deleteSchool = async (schoolId: string): Promise<string> => {
  const response = await fetch(`${baseUrl}/api/admin/school?id=${schoolId}`, {
    method: 'delete',
    credentials: 'include',
  });

  if (!response.ok) throw new Error(await response.text());

  return schoolId;
};

export default {
  getAllSchools,
  postSchool,
  deleteSchool,
};
