import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import SearchForm from './SearchForm';
import { QuestionIcon } from '@chakra-ui/icons';
import SearchResults from './SearchResults';
import StandardSpinner from './StandardSpinner';
import { useSearchByName } from '../api/hooks';
import { Respondent } from '../types/models';
import RoleChooser from './RoleChooser';

type PropTypes = {
  addRespondent: Function;
};

function AddRespondents(props: PropTypes) {
  const [search, setSearch] = useState('');
  const [chosenRespondent, setChosenRespondent] = useState<Respondent>();

  let { data: respondents, isFetching } = useSearchByName(
    search,
    'modalIndividualSearch'
  );

  const [role, setUserRole] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setUserRole('');
    respondents = undefined;
  }, []);

  useEffect(() => {
    if (chosenRespondent != undefined) {
      chosenRespondent.isEnabled = true;
      chosenRespondent.respondentRoleType = role;
    }
  }, [role]);

  function setSelectedRole(role: string) {
    setUserRole(role);
  }

  function setChosenAdditionalRespondent(respondent: Respondent) {
    setChosenRespondent(respondent);
  }

  return (
    <>
      <Button
        onClick={(e) => {
          onOpen();
          setUserRole('');
          setChosenRespondent(undefined);
          setSearch('');
        }}
        variant="link"
        color="blue.500"
      >
        + Legg til respondent (valgfritt)
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setChosenRespondent(undefined);
          respondents = undefined;
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Legg til responent <QuestionIcon color="blue.500" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SearchForm
              placeholder="Søk på navn eller fødselsnummer"
              onSubmit={setSearch}
            />
            {isFetching && <StandardSpinner />}
            {respondents && (
              <>
                <SearchResults
                  results={respondents}
                  setChosenRespondent={setChosenAdditionalRespondent}
                  modal={true}
                />
                {chosenRespondent && (
                  <RoleChooser setSelectedRole={setSelectedRole} value={role} />
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              isDisabled={role === ''}
              onClick={(e) => {
                props.addRespondent(chosenRespondent);
                onClose();
              }}
            >
              Velg
            </Button>
            <Button
              colorScheme={'blue'}
              variant="outline"
              ml={3}
              onClick={() => {
                onClose();
              }}
            >
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddRespondents;
