import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { User } from "../types/models";
import Select from "react-select";
import { maskFnr } from "../utilities/HelperFunctions";

type PropType = {
  onClose: Function;
  onSubmit: Function;
  user?: User;
};

const AdminCreateUpdateUser = (props: PropType) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nationalIdNumber, setFnr] = useState("");
  const [role, setRole] = useState("");

  const roles = [
    { label: "Helsepersonell", value: "Clinician" },
    { label: "Administrator", value: "SystemAdmin" },
  ];

  useEffect(() => {
    setFirstName(props.user?.firstName ?? "");
    setLastName(props.user?.lastName ?? "");
    setFnr(props.user?.nationalIdNumber ?? "");
    setRole(props.user?.userRoleType ?? "");
  }, []);

  return (
    <>
      <Box color={"#4A4A4A"}>
        <Heading mt={"32px"} fontSize={"20px"}>
          {!!!props.user ? "Legg til bruker" : "Endre bruker"}
        </Heading>

        <Text mt={"8px"} mb={"24px"}>
          Alle felt må fylles ut.
        </Text>
        <Text fontWeight={"bold"}>Fornavn</Text>
        <Input
          value={firstName}
          placeholder="Fornavn"
          required
          onChange={(event) => setFirstName(event?.target.value)}
        />
        <Text mt={"24px"} fontWeight={"bold"}>
          Etternavn
        </Text>
        <Input
          value={lastName}
          placeholder="Etternavn"
          required
          onChange={(event) => setLastName(event?.target.value)}
        />
        <Text mt={"24px"} fontWeight={"bold"}>
          Fødselsnummer
        </Text>
        <Input
          value={nationalIdNumber}
          placeholder="DDMMÅÅPPPPP"
          required
          onChange={(event) => setFnr(event?.target.value)}
        />
        <Text mt={"24px"} fontWeight={"bold"}>
          Rolle
        </Text>
        <Select
          defaultValue={roles.find(
            (role) => role.value === props.user?.userRoleType
          )}
          options={roles}
          placeholder="Velg rollen til brukeren"
          required
          onChange={(value) => {
            if (value) setRole(value.value);
          }}
          noOptionsMessage={() => "Fant ingen roller"}
        />
      </Box>
      <Flex
        justifyContent={"space-between"}
        mt={"24px"}
        mb={"24px"}
        width={"60%"}
      >
        <Button
          colorScheme={"blue"}
          padding={"21px 30px"}
          disabled={
            firstName === "" ||
            lastName === "" ||
            nationalIdNumber === "" ||
            role === ""
          }
          onClick={() =>
            props.onSubmit({
              id: props.user?.id ?? "00000000-0000-0000-0000-000000000000",
              firstName: firstName,
              lastName: lastName,
              nationalIdNumber: nationalIdNumber,
              userRoleType: role,
            })
          }
          mr={"10px"}
        >
          {!!!props.user ? "Legg til" : "Oppdater"}
        </Button>
        <Button
          variant={"outline"}
          colorScheme={"blue"}
          padding={"20px 30px"}
          onClick={() => props.onClose()}
        >
          Avbryt
        </Button>
      </Flex>
    </>
  );
};

export default AdminCreateUpdateUser;
