import React, { useMemo, useState } from "react";
import {
  useAllHealthCenters,
  useAllSchools,
  useDeleteSchool,
  usePostSchool,
} from "../api/hooks";
import {
  Spinner,
  Container,
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Column } from "react-table";
import { School } from "../types/models";
import { ConfirmModal } from "./ConfirmModal";
import AdminTable from "./AdminTable";
import AdminCreateUpdateSchool from "./AdminCreateUpdateSchool";

interface TableRows {
  id: string;
  name: string;
}

function SchoolList() {
  const toast = useToast();
  const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedSchool, setSelectedSchool] = React.useState<School>();

  let { data: healthCenters, isLoading: isLoadingHealthCenters } =
    useAllHealthCenters();
  let {
    data: schools,
    isLoading: isLoadingSchools,
    refetch: refetchSchools,
  } = useAllSchools();
  let { isLoading: isLoadingPostSchool, mutate: postSchool } = usePostSchool();
  let { isLoading: isLoadingDeleteSchool, mutate: deleteSchool } =
    useDeleteSchool();

  const data: TableRows[] = useMemo(
    () =>
      schools?.map((school, index) => {
        return {
          id: school.id,
          name: school.name ?? ""
        };
      }) ?? [],
    [schools]
  );

  const columns: Column<TableRows>[] = useMemo(
    () => [
      { Header: "Navn på skole", accessor: "name", disableSortBy: true },
    ],
    []
  );

  const handleCreateUpdate = (school: School) => {
    let isUpdate =
      school.id === "00000000-0000-0000-0000-000000000000" ? false : true;
    postSchool(school, {
      onSuccess: (data) => {
        setShowCreateEdit(false);
        toast({
          title: isUpdate ? "Skole er oppdatert" : "Skole er opprettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        refetchSchools();
      },
      onError(error: any, variables, context) {
        toast({
          title: isUpdate
            ? "Oppdatering av skole feilet"
            : "Opprett skole feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const handleDelete = (id: string) => {
    deleteSchool(id, {
      onSuccess: (data) => {
        setShowConfirmModal(false);
        toast({
          title: "Skole er slettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        refetchSchools();
      },
      onError(error: any, variables, context) {
        console.log(error);
        toast({
          title: "Sletting av skole feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  if (isLoadingSchools || isLoadingHealthCenters)
    return (
      <Container>
        <Spinner />
      </Container>
    );

  return (
    <>
      <Button
        colorScheme={"blue"}
        padding={"10px,34px,11px,34px"}
        onClick={() => {
          setSelectedSchool(undefined);
          setShowCreateEdit(true);
        }}
      >
        + Legg til skole
      </Button>
      <Box mt={"20px"}>
        <AdminTable
          columns={columns}
          data={data}
          onChange={(id: string) => {
            setSelectedSchool(schools?.find((h) => h.id === id));
            setShowCreateEdit(true);
          }}
          onDelete={(id: string) => {
            setSelectedSchool(schools?.find((h) => h.id === id));
            setShowConfirmModal(true);
          }}
        />
        <Modal
          isOpen={showCreateEdit}
          onClose={() => {
            setShowCreateEdit(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <AdminCreateUpdateSchool
                onClose={() => {
                  setShowCreateEdit(false);
                }}
                onSubmit={handleCreateUpdate}
                school={selectedSchool}
                healthCenters={healthCenters}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <ConfirmModal
          show={showConfirmModal}
          onClose={setShowConfirmModal}
          closeButtonText="Avbryt"
          text={`Er du sikker på at du vil slette skole: ${selectedSchool?.name} ?`}
          onSubmit={() => handleDelete(selectedSchool?.id ?? "")}
          submitButtonText="Slett"
        />
      </Box>
    </>
  );
}

export default SchoolList;
