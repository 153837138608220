import React from "react";
import { ReactElement } from "react";

type PropType = {
  size?: string;
};

/**
 * EditIcon Two sizes available, 24 or 32 px
 * @param size String md=32px default is 24px
 * @returns returns icon with given or default size
 */
function EditIcon(props: PropType): ReactElement {
  switch (props.size) {
    case "md":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="6" fill="#DFEEF9" />
          <path
            d="M18.1364 12.8892L19.1112 13.864L9.6949 23.2596H8.74082V22.3055L18.1364 12.8892ZM21.8697 6.66699C21.6105 6.66699 21.3408 6.7707 21.1438 6.96773L19.246 8.86551L23.1349 12.7544L25.0327 10.8566C25.4371 10.4522 25.4371 9.7781 25.0327 9.3944L22.606 6.96773C22.3986 6.76033 22.1393 6.66699 21.8697 6.66699ZM18.1364 9.97514L6.66675 21.4448V25.3337H10.5556L22.0253 13.864L18.1364 9.97514Z"
            fill="#01459E"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="6" fill="#DFEEF9" />
          <path
            d="M13.6022 9.66667L14.3333 10.3978L7.27111 17.4444H6.55556V16.7289L13.6022 9.66667ZM16.4022 5C16.2078 5 16.0056 5.07778 15.8578 5.22556L14.4344 6.64889L17.3511 9.56556L18.7744 8.14222C19.0778 7.83889 19.0778 7.33333 18.7744 7.04556L16.9544 5.22556C16.7989 5.07 16.6044 5 16.4022 5ZM13.6022 7.48111L5 16.0833V19H7.91667L16.5189 10.3978L13.6022 7.48111Z"
            fill="#01459E"
          />
        </svg>
      );
  }
}

export { EditIcon };
