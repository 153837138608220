import { QuestionIcon } from "@chakra-ui/icons";
import { Heading, Box, VStack } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { AssessmentStatusTable } from "../components/AssessmentStatusTable";
import { useGetAllAssessments, useUserInfo } from "../api/hooks";
import { Column } from "react-table";
import { Respondent } from "../types/models";
import { StatusPlannedIcon } from "../icons/StatusPlannedIcon";
import { StatusJournalIcon } from "../icons/StatusJournalIcon";
import { StatusFinishedIcon } from "../icons/StatusSentIcon";
import { AssessmentStatusFilter } from "../components/AssessmentStatusFilter";
import { StatusDraftIcon } from "../icons/StatusDraftIcon";
import { getAge, maskFnr } from "../utilities/HelperFunctions";
import { StatusAbortedIcon } from "../icons/StatusAbortedIcon";
import { StatusSentJournalIcon } from "../icons/StatusSentJournalIcon";

interface TableRows {
  id: string;
  person: { name: string; age: string; SSN: string };
  status: ReactElement | undefined;
  statusMainText: string;
  statusText: string;
  date: string;
  statusReports: ReactElement[];
}

export function getStatusBadge(status: string, column?: string): ReactElement {
    switch (status.toLowerCase()) {
      case "draft":
        return (
          <Box p={"2px"}>
            <StatusDraftIcon />
          </Box>
        );
      case "planned":
        return (
          <Box p={"2px"}>
            <StatusPlannedIcon />
          </Box>
        );
      case "sent":
        if (column === 'statusReports') {
          return (
            <Box p={"2px"}>
              <StatusSentJournalIcon />
            </Box>
          );
        } else {
          return (
            <Box p={"2px"}>
            <StatusFinishedIcon />
          </Box>
        );
      }
      case "journaled":
        return (
          <Box p={"2px"}>
            <StatusJournalIcon />
          </Box>
        );
      case "closed":
        return (
          <Box p={"2px"}>
            <StatusAbortedIcon />
          </Box>
        );
    }
    return <></>;
  };

const AssessmentStatus = () => {
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterName, setFilterName] = useState("");

  const { data: userInfo } = useUserInfo();
  let {
    data: assessments,
    isLoading: isLoadingAssessments,
    refetch: refetchAssessments,
  } = useGetAllAssessments();

  useEffect(() => {
    refetchAssessments();
  }, []);

  const getRespondentAssessments = (
    respondents: Respondent[],
    individualAssessmentId: string
  ) => {
    const uniqueStatuses = new Set();
    respondents.forEach((respondent) => {
      respondent.assessments.forEach((assessment) => {
        if (assessment.individualAssessmentId !== individualAssessmentId)
          return;
        uniqueStatuses.add(assessment.assessmentStatus);
      });
    });
    return Array.from(uniqueStatuses).sort().join(", ");
  };

  const getRespondentAssessmentsBadge = (
    respondents: Respondent[],
    individualAssessmentId: string
  ) => {
    const statusMap = new Map<string, ReactElement>();

    respondents.forEach((respondent) => {
      respondent.assessments.forEach((assessment) => {
        if (assessment.individualAssessmentId !== individualAssessmentId)
          return;
        const statusBadge = getStatusBadge(assessment.assessmentStatus, "statusReports");
        statusMap.set(assessment.assessmentStatus, statusBadge);
      });
    });

    return Array.from(statusMap.values()).sort();
  };

  const data: TableRows[] = useMemo(
    () =>
      assessments
        ?.filter((a) => a.assessmentStatus !== "Draft")
        .map((assessment, index) => {
          const child = assessment.children && assessment.children[0];
          return {
            id: assessment.id,
            person: {
              name: child ? child.name : "",
              age: child ? getAge(new Date(child.birthDate)) ?? "" : "",
              SSN: child ? child.nationalIdNumber : "",
            },
            status: getStatusBadge(assessment.assessmentStatus, "status"),
            statusMainText: assessment.assessmentStatus,
            statusText: getRespondentAssessments(
              assessment.respondents,
              assessment.id
            ),
            date: new Date(assessment.assessmentDate).toLocaleDateString(
              "no-NB",
              { year: "numeric", month: "2-digit", day: "2-digit" }
            ),
            statusReports: getRespondentAssessmentsBadge(
              assessment.respondents,
              assessment.id
            ),
          };
        }) ?? [],
    [assessments]
  );

  const dataDrafts: TableRows[] = useMemo(
    () =>
      assessments
        ?.filter((a) => a.assessmentStatus === "Draft")
        .map((assessment, index) => {
          const child = assessment.children && assessment.children[0];
          return {
            id: assessment.id,
            person: {
              name: child ? child.name : "Informasjon mangler",
              age: child ? getAge(new Date(child.birthDate)) ?? "" : "",
              SSN: child
                ? "(f.nr." + maskFnr(child.nationalIdNumber) + ")"
                : "",
            },
            status: getStatusBadge(assessment.assessmentStatus, "status"),
            statusMainText: assessment.assessmentStatus,
            statusText: getRespondentAssessments(
              assessment.respondents,
              assessment.id
            ),
            date: new Date(assessment.assessmentDate).toLocaleDateString(
              "no-NB",
              { year: "numeric", month: "2-digit", day: "2-digit" }
            ),
            statusReports: getRespondentAssessmentsBadge(
              assessment.respondents,
              assessment.id
            ),
          };
        }) ?? [],
    [assessments]
  );

  const columns: Column<TableRows>[] = useMemo(
    () => [
      {
        Header: "Navn",
        accessor: (row: TableRows) =>
          `${row.person.name}, ${row.person.age} (f.nr. ${maskFnr(
            row.person.SSN
          )})`,
        disableSortBy: false,
      },
      {
        Header: "SSN",
        accessor: (row: TableRows) => row.person.SSN,
        disableSortBy: false,
      },
      {
        Header: "Status kartlegging",
        accessor: "status",
        disableSortBy: true,
      },
      {
        Header: "Status main",
        accessor: "statusMainText",
        disableSortBy: true,
      },
      {
        Header: "Status text",
        accessor: "statusText",
        disableSortBy: true,
      },
      {
        Header: "Status rapporter",
        accessor: "statusReports",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <Box>
            {(value as ReactElement[]).map((badge, index) => (
              <Box key={index} p={"2px"}>
                {badge}
              </Box>
            ))}
          </Box>
        ),
      },
      {
        Header: "Dato for utsendelse",
        accessor: "date",
        disableSortBy: false,
      },
    ],
    []
  );

  function renderAssessmentStatusForm() {
    if (!!!userInfo?.isAuthenticated) return <Navigate to="/" />;
    // if (env.REACT_APP_FEATURE_ASSESSMENT_STATUS === "false")
    //   return <FeatureDisabled />;

    return (
      <Box>
        <AssessmentStatusFilter
          filterName={filterName}
          setFilterName={setFilterName}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
        />

        <AssessmentStatusTable
          data={data}
          dataDrafts={dataDrafts}
          columns={columns}
          assessments={assessments}
          filterStatus={filterStatus}
          filterName={filterName}
          refetchAssessments={refetchAssessments}
        />
      </Box>
    );
  }

  return (
    <VStack spacing="24px" align={"flex-start"} p="9">
      <Heading size="xl" mb="8">
        Status kartlegging <QuestionIcon color="blue.500" ml={2} pb={1} />
      </Heading>

      {renderAssessmentStatusForm()}
    </VStack>
  );
};

export default AssessmentStatus;
