import React, { useEffect } from "react";
import { Heading, HStack, Text } from "@chakra-ui/react";
import { Respondent } from "../types/models";
import { getAge, maskFnr } from "../utilities/HelperFunctions";

type PropTypes = {
  chosenRespondent: Respondent;
};

function ChosenRespondent(props: PropTypes) {
  const { chosenRespondent } = props;

  useEffect(() => {}, [chosenRespondent]);

  return (
    <>
      <Heading size="xs" mt={8} mb={2}>
        Valgt respondent:
      </Heading>

      <HStack>
        <Text
          display="flex"
          fontSize="xl"
          padding-top="2px"
          fontWeight="700"
          key={chosenRespondent.id}
        >
          {chosenRespondent.name +
            ", " +
            getAge(new Date(chosenRespondent.birthDate))}
        </Text>
        <Text display="flex" ml={3} fontSize="md" fontWeight="400">
          {"(f.nr. " + maskFnr(chosenRespondent.nationalIdNumber) + ")"}
        </Text>
      </HStack>
    </>
  );
}

export default ChosenRespondent;
