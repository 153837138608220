import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HealthCenterModel, School } from "../types/models";
import Select from "react-select";

type PropType = {
  onClose: Function;
  onSubmit: Function;
  school?: School;
  healthCenters?: HealthCenterModel[];
};

const AdminCreateUpdateSchool = (props: PropType) => {
  const [school, setSchool] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [healthCenter, setHealthCenter] = useState("");

  useEffect(() => {
    setSchool(props.school?.name ?? "");
  }, []);

  return (
    <>
      <Box color={"#4A4A4A"}>
        <Heading mt={"32px"} fontSize={"20px"}>
          {!!!props.school ? "Legg til skole" : "Endre skole"}
        </Heading>

        <Text mt={"8px"} mb={"24px"}>
          Alle felt må fylles ut.
        </Text>
        <Text fontWeight={"bold"}>Navn på skole</Text>
        <Input
          value={school}
          placeholder="Navn på skole"
          onChange={(event) => setSchool(event?.target.value)}
        />
      </Box>
      <Flex
        justifyContent={"space-between"}
        mt={"24px"}
        mb={"24px"}
        width={"60%"}
      >
        <Button
          colorScheme={"blue"}
          padding={"21px 30px"}
          disabled={school === "" || orgNo === "" || healthCenter === ""}
          onClick={() =>
            props.onSubmit({
              id: props.school?.id ?? "00000000-0000-0000-0000-000000000000",
              name: school,
              organizationNo: orgNo,
              healthCenterId: healthCenter,
            })
          }
          mr={"10px"}
        >
          {!!!props.school ? "Legg til" : "Oppdater"}
        </Button>
        <Button
          variant={"outline"}
          colorScheme={"blue"}
          padding={"20px 30px"}
          onClick={() => props.onClose()}
        >
          Avbryt
        </Button>
      </Flex>
    </>
  );
};

export default AdminCreateUpdateSchool;
