import React, { useState } from "react";
import { Respondent as RespondentModel } from "../types/models";
import {
  Heading,
  Stack,
  Checkbox,
  CheckboxGroup,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { RemoveIcon } from "../icons/RemoveIcon";
import { EditIcon } from "../icons/EditIcon";
import EditRespondentModal from "./EditRespondentModal";
import { InfoModal } from "./InfoModal";
import { ConfirmModal } from "./ConfirmModal";
import { getAgeNumber } from "../utilities/HelperFunctions";

function getUserRole(userRole: string) {
  switch (userRole.toLowerCase()) {
    case "kindergartenteacher":
      return "Barnehagelærer";
    case "teacher":
      return "Lærer";
    case "parent":
      return "Foresatt";
    default:
      return "";
  }
}

function getPhoneNumber(phoneNumber: any) {
  if (phoneNumber != null && phoneNumber !== "") {
    return phoneNumber;
  }

  return "mobilnummer mangler";
}

type PropTypes = {
  childRespondent: RespondentModel;
  additionalRespondents: RespondentModel[] | undefined;
  removeRespondent: Function;
  updateRespondentList: Function;
};

function Respondents(props: PropTypes) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [infoModalModel, setInfoModalModel] = useState<{
    show: boolean;
    type: string;
  }>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [currentRespondent, setCurrentRespondent] = useState<RespondentModel>();

  function getRespondentByIndex(index: number) {
    let respondent = props.additionalRespondents
      ? props.additionalRespondents[index]
      : undefined;
    setCurrentRespondent(respondent);
  }

  function ValidateRespondent(currentRespondent: RespondentModel) {
    switch (currentRespondent.respondentRoleType.toLowerCase()) {
      case "kindergartenteacher":
        let kindergartenteacher = props.additionalRespondents?.filter(
          (x) =>
            x.respondentRoleType === "kindergartenteacher" &&
            x.isEnabled === true
        );

        if (kindergartenteacher && kindergartenteacher.length >= 2) {
            setInfoModalModel({
              show: true,
              type: "Du kan kun velge en barnehagelærer. Fjern allerede valgt barnehagelærer fra listen.",
            });
          currentRespondent.isEnabled = false;
        } else currentRespondent.isEnabled = !currentRespondent.isEnabled;
        return currentRespondent;
      case "teacher":
        let teacher = props.additionalRespondents?.filter(
          (x) => x.respondentRoleType === "teacher" && x.isEnabled === true
        );

        if (teacher && teacher?.length >= 2) {
            setInfoModalModel({
              show: true,
              type: "Du kan kun velge en lærer. Fjern allerede valgt lærer fra listen.",
            });
          currentRespondent.isEnabled = false;
        } else currentRespondent.isEnabled = !currentRespondent.isEnabled;
        return currentRespondent;
      case "parent":
        let parents = props.additionalRespondents?.filter(
          (x) => x.respondentRoleType === "parent"
        );

        if (parents && parents.length >= 3) {
            setInfoModalModel({
              show: true,
              type: "Du kan kun velge to foresatte. Fjern minst en allerede valgt foresatt fra listen.",
            });
          currentRespondent.isEnabled = false;
        } else currentRespondent.isEnabled = !currentRespondent.isEnabled;
        return currentRespondent;
      default:
        return currentRespondent;
    }
  }

  function isOverrideDefaultEnabledByChildAge(isDefaultEnabled: boolean) {
    let child = props.childRespondent;
    const birthDate = new Date(child.birthDate);
    var age = getAgeNumber(birthDate);
    if (age != undefined && age >= 16) return false;
    return isDefaultEnabled;
  }

  return (
    <>
      <Heading size="xs" mt={8} mb={1}>
        Respondenter
      </Heading>
      <Text>
        Huk av for hvem spørreundersøkelsen(e) skal sendes til, i tillegg til
        barn:
      </Text>
      <CheckboxGroup colorScheme="blue">
        <Stack spacing={3} mt={5} mb={4}>
          <div>
            <Checkbox
              size="lg"
              defaultChecked={props.childRespondent.isEnabled}
              isChecked={props.childRespondent.isEnabled}
              onChange={(e) => {
                e.target.checked
                  ? (props.childRespondent.isEnabled = true)
                  : (props.childRespondent.isEnabled = false);
                  props.updateRespondentList(props.childRespondent);
              }}
            >
              <Text fontSize="md" fontWeight="bold">
                (Barn){" "}
                {props.childRespondent.name +
                  ", " +
                  getPhoneNumber(props.childRespondent.phoneNumber)}
              </Text>
            </Checkbox>
            {
              <IconButton
                border={0}
                onClick={() => {
                  setCurrentRespondent(props.childRespondent);
                  setShowModal(true);
                }}
                ml="2"
                size="sm"
                mt="-1"
                variant="outline"
                colorScheme="blue"
                aria-label="Edit"
                icon={<EditIcon />}
              />
            }
          </div>

          {props.additionalRespondents?.map(
            (respondent: RespondentModel, i: number) => (
              <div key={i}>
                <Checkbox
                  size="lg"
                  key={respondent.id}
                  defaultChecked={isOverrideDefaultEnabledByChildAge(
                    respondent.isDefaultParent
                  )}
                  isChecked={respondent.isEnabled}
                  onChange={(e) => {
                    let respondentToUpdate = ValidateRespondent(respondent);
                    props.updateRespondentList(respondentToUpdate);
                  }}
                >
                  <Text fontSize="md" fontWeight="bold">
                    ({getUserRole(respondent.respondentRoleType)}){" "}
                    {respondent.name +
                      ", " +
                      getPhoneNumber(respondent.phoneNumber)}
                  </Text>
                </Checkbox>
                {
                  <IconButton
                    border={0}
                    onClick={() => {
                      getRespondentByIndex(i);
                      setShowModal(true);
                    }}
                    ml="2"
                    mt="-1"
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    aria-label="Edit"
                    icon={<EditIcon />}
                  />
                }

                {!!!respondent.isDefaultParent && (
                  <IconButton
                    border={0}
                    onClick={() => {
                      getRespondentByIndex(i);
                      setShowConfirmModal(true);
                    }}
                    ml="2"
                    size="sm"
                    variant="outline"
                    colorScheme="blue"
                    aria-label="Delete"
                    icon={<RemoveIcon />}
                  />
                )}
              </div>
            )
          )}
        </Stack>
      </CheckboxGroup>
      {
        <>
          <EditRespondentModal
            show={showModal}
            headerText={currentRespondent?.name ?? ""}
            onSubmit={props.updateRespondentList}
            submitButtonText="Lagre"
            abortButtonText="Avbryt"
            respondent={currentRespondent}
            onClose={setShowModal}
          />

          <InfoModal
            show={infoModalModel?.show ?? false}
            onClose={setInfoModalModel}
            closeButtonText="Lukk"
            text={infoModalModel?.type ?? ""}
            showHeaderIcon={true}
          />
          <ConfirmModal
            show={showConfirmModal}
            onClose={setShowConfirmModal}
            closeButtonText="Avbryt"
            text={`Er du sikker på at du vil slette respondenten ${currentRespondent?.name}?`}
            onSubmit={() => props.removeRespondent(currentRespondent)}
            submitButtonText="Slett"
          />
        </>
      }
    </>
  );
}

export default Respondents;
