import { env } from '../env';
import { UserInfo } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL;

export const login = async (): Promise<string> => {
  const response = await fetch(`${baseUrl}/session/login?redirect=${baseUrl}`, {
    redirect: 'manual',
  });

  if (response.type === 'opaqueredirect') {
    window.location.href = response.url;
  }

  if (!response.ok) throw new Error(await response.text());

  const result = await response.json();

  return result;
};

export const logout = async (): Promise<void> => {
  const response = await fetch(
    `${baseUrl}/session/logout?redirect=${env.REACT_APP_BASE_URL}`,
    {
      credentials: 'include',
      redirect: 'manual',
    }
  );
  if (response.type === 'opaqueredirect') {
    window.location.href = response.url;
  }

  if (!response.ok) throw new Error(await response.text());

  const result = await response.json();

  return result;
};

export const getInfo = async (): Promise<UserInfo> => {
  const response = await fetch(`${baseUrl}/session/info`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
  if (!response.ok) throw new Error(await response.text());

  const info = await response.json();

  return info;
};
