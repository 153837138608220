import React, { useState } from "react";
import { Box, getToken, Heading, VStack, Text } from "@chakra-ui/react";
import SearchForm from "../components/SearchForm";
import { QuestionIcon } from "@chakra-ui/icons";
import { useSearchByName, useUserInfo } from "../api/hooks";
import SearchResults from "../components/SearchResults";
import StandardSpinner from "../components/StandardSpinner";
import { Respondent } from "../types/models";
import { Navigate } from "react-router-dom";
import { env } from "../env";
import FeatureDisabled from "../components/FeatureDisabled";

function HeightAndWeightAssessment() {
  const { data: userInfo } = useUserInfo();

  const [search, setSearch] = useState("");

  const { data: respondents, isFetching } = useSearchByName(
    search,
    "heightMainSearch"
  );
  const [chosenRespondent, setChosenRespondent] = useState<Respondent>();

  function renderHeightAndWeightAssessmentForm() {
    if (!!!userInfo?.isAuthenticated) return <Navigate to="/" />;
    if (env.REACT_APP_FEATURE_HEIGHT_AND_WEIGHT_ASSESSMENT === "false")
      return <FeatureDisabled />;
    return (
      <Box p="9">
        <Heading size="xl" mb="16" fontWeight="400">
          Registrer høyde, vekt og oppfølgingsgruppe
          <QuestionIcon color="blue.500" ml={4} />
        </Heading>
        <SearchForm
          heading="Søk opp barn med personregisteret"
          placeholder="Søk opp barnets navn eller fødselsnummer (11 siffer)"
          onSubmit={setSearch}
        />
        {isFetching && <StandardSpinner />}
        {!isFetching && respondents && (
          <SearchResults
            results={respondents}
            setChosenRespondent={setChosenRespondent}
          />
        )}
      </Box>
    );
  }

  return (
    <VStack spacing="24px" align={"flex-start"} p="9">
      <Heading size="xl" mb="8">
        Registrer høyde, vekt og oppfølgingsgruppe
      </Heading>

      {renderHeightAndWeightAssessmentForm()}
    </VStack>
  );
}

export default HeightAndWeightAssessment;
