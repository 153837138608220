import React from "react";
import { Heading, Select } from "@chakra-ui/react";

type GenericDropdownProps = {
    heading: string;
    placeHolder: string;
    items: any[];
    onChange: Function;
    value?: string | null;
};

/** Requires that each item has an id and name property */
function GenericDropdown(props: GenericDropdownProps) {
    return (
        <>
            <Heading size="xs" mt={5} mb={1}>
                {props.heading}
            </Heading>
            <Select
                placeholder={props.placeHolder}
                size="sm"
                width="35%"
                value={props.value ?? ""}
                onChange={(e) => {
                    props.onChange(e.target.value);
                }}
            >
                {props.items?.map((item) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </Select>
        </>
    );
}
export default GenericDropdown;
