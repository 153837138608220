import React, { useMemo, useState } from "react";
import {
  useAllHealthCenters,
  useAllSchools,
  useAllUsers,
  useDeleteSchool,
  useDeleteUser,
  usePostSchool,
  usePostUser,
} from "../api/hooks";
import {
  Spinner,
  Container,
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Column } from "react-table";
import { School, User } from "../types/models";
import { ConfirmModal } from "./ConfirmModal";
import AdminTable from "./AdminTable";
import AdminCreateUpdateSchool from "./AdminCreateUpdateSchool";
import AdminCreateUpdateUser from "./AdminCreateUpdateUser";
import { maskFnr } from "../utilities/HelperFunctions";

interface TableRows {
  id: string;
  name: string;
  nationalIdNumber: string;
  role: string;
}

function UserList() {
  const toast = useToast();
  const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<User>();

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useAllUsers();

  const { isLoading: isLoadingPostUser, mutate: postUser } = usePostUser();
  let { isLoading: isLoadingDeleteUser, mutate: deleteUser } = useDeleteUser();

  const data: TableRows[] = useMemo(
    () =>
      users?.map((user, index) => {
        return {
          id: user.id,
          name: user.firstName + " " + user.lastName ?? "",
          nationalIdNumber: maskFnr(user.nationalIdNumber) ?? "",
          role:
            user.userRoleType == "SystemAdmin"
              ? "Administrator"
              : "Helsepersonell",
        };
      }) ?? [],
    [users]
  );

  const columns: Column<TableRows>[] = useMemo(
    () => [
      { Header: "Navn", accessor: "name", disableSortBy: true },
      {
        Header: "Fødselsnummer",
        accessor: "nationalIdNumber",
        disableSortBy: true,
      },
      {
        Header: "Rolle",
        accessor: "role",
        disableSortBy: false,
      },
    ],
    []
  );

  const handleCreateUpdate = (user: User) => {
    let isUpdate =
      user.id === "00000000-0000-0000-0000-000000000000" ? false : true;
    postUser(user, {
      onSuccess: () => {
        setShowCreateEdit(false);
        toast({
          title: isUpdate ? "Bruker er oppdatert" : "Bruker er opprettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        refetchUsers();
      },
      onError(error: any) {
        toast({
          title: isUpdate
            ? "Oppdatering av bruker feilet"
            : "Opprett bruker feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const handleDelete = (id: string) => {
    deleteUser(id, {
      onSuccess: () => {
        setShowConfirmModal(false);
        toast({
          title: "Bruker er slettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        refetchUsers();
      },
      onError(error: any) {
        console.log(error);
        toast({
          title: "Sletting av bruker feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  if (isLoadingUsers)
    return (
      <Container>
        <Spinner />
      </Container>
    );

  return (
    <>
      <Button
        colorScheme={"blue"}
        padding={"10px,34px,11px,34px"}
        onClick={() => {
          setSelectedUser(undefined);
          setShowCreateEdit(true);
        }}
      >
        + Legg til bruker
      </Button>
      <Box mt={"20px"}>
        <AdminTable
          columns={columns}
          data={data}
          onChange={(id: string) => {
            setSelectedUser(users?.find((h) => h.id === id));
            setShowCreateEdit(true);
          }}
          onDelete={(id: string) => {
            setSelectedUser(users?.find((h) => h.id === id));
            setShowConfirmModal(true);
          }}
        />
        <Modal
          isOpen={showCreateEdit}
          onClose={() => {
            setShowCreateEdit(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <AdminCreateUpdateUser
                onClose={() => {
                  setShowCreateEdit(false);
                }}
                onSubmit={handleCreateUpdate}
                user={selectedUser}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <ConfirmModal
          show={showConfirmModal}
          onClose={setShowConfirmModal}
          closeButtonText="Avbryt"
          text={`Er du sikker på at du vil slette bruker: ${
            selectedUser?.firstName + " " + selectedUser?.lastName
          }?`}
          onSubmit={() => handleDelete(selectedUser?.id ?? "")}
          submitButtonText="Slett"
        />
      </Box>
    </>
  );
}

export default UserList;
