import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  SelectField,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  RespondentRoleAssessmentPlan,
  RespondentRoleTypes,
} from "../types/models";
import Select from "react-select";
import {
  useGetAllCheckwareAssessmentplan,
  usePostRespondentRoleAssessmentPlan,
} from "../api/hooks";

type PropType = {
  onClose: Function;
  assessmentPlan?: RespondentRoleAssessmentPlan;
  healthCenterId?: string;
  treatmentTypeId: string;
  healthCheckTypeId?: string;
  selectedRoleAssessmentPlan?: RespondentRoleAssessmentPlan;
};

const AdminAddRole = (props: PropType) => {
  const toast = useToast();
  const [childRole, setChildRole] = useState<boolean>(false);
  const [mainParentRole, setMainParentRole] = useState<boolean>(false);
  const [secondParentRole, setSecondParentRole] = useState<boolean>(false);
  const [teacherRole, setTeacherRole] = useState<boolean>(false);
  const [kindergartenteacherRole, setKindergartenteacherRole] =
    useState<boolean>(false);
  const [childAssessmentPlan, setChildAssessmentPlan] =
    useState<RespondentRoleAssessmentPlan>();
  const [mainParentAssessmentPlan, setMainParentAssessmentPlan] =
    useState<RespondentRoleAssessmentPlan>();
  const [secondaryParentAssessmentPlan, setSecondaryParentAssessmentPlan] =
    useState<RespondentRoleAssessmentPlan>();
  const [teacherAssessmentPlan, setTeacherAssessmentPlan] =
    useState<RespondentRoleAssessmentPlan>();
  const [
    kindergartenteacherAssessmentPlan,
    setKindergartenteacherAssessmentPlan,
  ] = useState<RespondentRoleAssessmentPlan>();
  const [roleAssessmentPlans, setRoleAssessmentPlans] =
    useState<RespondentRoleAssessmentPlan[]>();

  let { data: assessmentplan } = useGetAllCheckwareAssessmentplan(
    props.treatmentTypeId
  );
  let { isLoading: isLoadingPostRole, mutate: postRespondentRole } =
    usePostRespondentRoleAssessmentPlan();

  const handleChildRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChildRole(e.target.checked);
  };
  const handleTeacherRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeacherRole(e.target.checked);
  };
  const handleKindergartenteacherRoleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setKindergartenteacherRole(e.target.checked);
  };
  const handleMainParentRoleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMainParentRole(e.target.checked);
  };
  const handleSecondParentRoleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSecondParentRole(e.target.checked);
  };

  const handleCreateUpdateRole = () => {
    let isUpdateChild =
      childAssessmentPlan?.id === "00000000-0000-0000-0000-000000000000"
        ? false
        : true;

    if (childAssessmentPlan) {
      postRespondentRole(childAssessmentPlan, {
        onSuccess: (data) => {
          setRoleAssessmentPlans(data);
          toast({
            title: isUpdateChild ? "Rolle er oppdatert" : "Rolle er opprettet",
            description: "",
            status: "info",
            isClosable: true,
            position: "top",
          });
        },
        onError(error: any, variables, context) {
          toast({
            title: isUpdateChild
              ? "Oppdatering av rolle feilet"
              : "Opprett rolle feilet",
            description: "Vennligst prøv igjen senere",
            status: "error",
            isClosable: true,
            position: "top",
          });
        },
      });
    }

    let isUpdateMainParent =
      mainParentAssessmentPlan?.id === "00000000-0000-0000-0000-000000000000"
        ? false
        : true;
    if (mainParentAssessmentPlan) {
      postRespondentRole(mainParentAssessmentPlan, {
        onSuccess: (data) => {
          setRoleAssessmentPlans(data);
          toast({
            title: isUpdateMainParent
              ? "Rolle er oppdatert"
              : "Rolle er opprettet",
            description: "",
            status: "info",
            isClosable: true,
            position: "top",
          });
        },
        onError(error: any, variables, context) {
          toast({
            title: isUpdateMainParent
              ? "Oppdatering av rolle feilet"
              : "Opprett rolle feilet",
            description: "Vennligst prøv igjen senere",
            status: "error",
            isClosable: true,
            position: "top",
          });
        },
      });
    }

    let isUpdateSecondaryParent =
      secondaryParentAssessmentPlan?.id ===
      "00000000-0000-0000-0000-000000000000"
        ? false
        : true;
    if (secondaryParentAssessmentPlan) {
      postRespondentRole(secondaryParentAssessmentPlan, {
        onSuccess: (data) => {
          setRoleAssessmentPlans(data);
          toast({
            title: isUpdateSecondaryParent
              ? "Rolle er oppdatert"
              : "Rolle er opprettet",
            description: "",
            status: "info",
            isClosable: true,
            position: "top",
          });
        },
        onError(error: any, variables, context) {
          toast({
            title: isUpdateSecondaryParent
              ? "Oppdatering av rolle feilet"
              : "Opprett rolle feilet",
            description: "Vennligst prøv igjen senere",
            status: "error",
            isClosable: true,
            position: "top",
          });
        },
      });
    }

    let isUpdateTeacher =
      teacherAssessmentPlan?.id === "00000000-0000-0000-0000-000000000000"
        ? false
        : true;
    if (teacherAssessmentPlan) {
      postRespondentRole(teacherAssessmentPlan, {
        onSuccess: (data) => {
          setRoleAssessmentPlans(data);
          toast({
            title: isUpdateTeacher
              ? "Rolle er oppdatert"
              : "Rolle er opprettet",
            description: "",
            status: "info",
            isClosable: true,
            position: "top",
          });
        },
        onError(error: any, variables, context) {
          toast({
            title: isUpdateTeacher
              ? "Oppdatering av rolle feilet"
              : "Opprett rolle feilet",
            description: "Vennligst prøv igjen senere",
            status: "error",
            isClosable: true,
            position: "top",
          });
        },
      });
    }

    let isUpdateKindergartenteacher =
      kindergartenteacherAssessmentPlan?.id ===
      "00000000-0000-0000-0000-000000000000"
        ? false
        : true;
    if (kindergartenteacherAssessmentPlan) {
      postRespondentRole(kindergartenteacherAssessmentPlan, {
        onSuccess: (data) => {
          setRoleAssessmentPlans(data);
          toast({
            title: isUpdateKindergartenteacher
              ? "Rolle er oppdatert"
              : "Rolle er opprettet",
            description: "",
            status: "info",
            isClosable: true,
            position: "top",
          });
        },
        onError(error: any, variables, context) {
          toast({
            title: isUpdateKindergartenteacher
              ? "Oppdatering av rolle feilet"
              : "Opprett rolle feilet",
            description: "Vennligst prøv igjen senere",
            status: "error",
            isClosable: true,
            position: "top",
          });
        },
      });
    }
  };

  return (
    <>
      <Box color={"#4A4A4A"}>
        <Heading mt={"32px"} fontSize={"20px"}>
          {!props.selectedRoleAssessmentPlan ? "Legg til rolle" : "Endre rolle"}
        </Heading>

        <Text mt={"24px"} fontWeight={"bold"}>
          Kartleggingsplan
        </Text>

        <Stack direction="column" mt="24px">
          <Checkbox onChange={handleChildRoleChange} mt={"24px"}>
            Barnet
          </Checkbox>
          {childRole === true ? (
            <Select
              value={assessmentplan?.find((s) => s.id)}
              isSearchable={true}
              options={assessmentplan}
              getOptionValue={(options) => options.assessmentPlanId}
              getOptionLabel={(options) => options.name}
              placeholder="Søk her eller velg direkte fra listen"
              onChange={(value) => {
                setChildAssessmentPlan({
                  name: value?.name ?? "",
                  assessmentPlanId: value?.assessmentPlanId ?? "",
                  respondentRoleId: RespondentRoleTypes.child.toString(),
                  id:
                    props.assessmentPlan?.id ??
                    "00000000-0000-0000-0000-000000000000",
                  healthCenterId: props.healthCenterId ?? "",
                  healthCheckTypeId: props.healthCheckTypeId ?? "",
                });
              }}
              noOptionsMessage={() => "Fant ingen kartlegginger"}
            />
          ) : (
            <></>
          )}

          <Checkbox onChange={handleMainParentRoleChange}>Foresatt 1</Checkbox>
          {mainParentRole === true ? (
            <Select
              value={assessmentplan?.find((s) => s.id)}
              isSearchable={true}
              options={assessmentplan}
              getOptionValue={(options) => options.assessmentPlanId}
              getOptionLabel={(options) => options.name}
              placeholder="Søk her eller velg direkte fra listen"
              onChange={(value) => {
                setMainParentAssessmentPlan({
                  name: value?.name ?? "",
                  assessmentPlanId: value?.assessmentPlanId ?? "",
                  respondentRoleId: RespondentRoleTypes.parent1.toString(),
                  id:
                    props.assessmentPlan?.id ??
                    "00000000-0000-0000-0000-000000000000",
                  healthCenterId: props.healthCenterId ?? "",
                  healthCheckTypeId: props.healthCheckTypeId ?? "",
                });
              }}
              noOptionsMessage={() => "Fant ingen kartlegginger"}
            />
          ) : (
            <></>
          )}

          <Checkbox onChange={handleSecondParentRoleChange}>
            Foresatt 2
          </Checkbox>
          {secondParentRole === true ? (
            <Select
              value={assessmentplan?.find((s) => s.id)}
              isSearchable={true}
              options={assessmentplan}
              getOptionValue={(options) => options.assessmentPlanId}
              getOptionLabel={(options) => options.name}
              placeholder="Søk her eller velg direkte fra listen"
              onChange={(value) => {
                setSecondaryParentAssessmentPlan({
                  name: value?.name ?? "",
                  assessmentPlanId: value?.assessmentPlanId ?? "",
                  respondentRoleId: RespondentRoleTypes.parent2.toString(),
                  id:
                    props.assessmentPlan?.id ??
                    "00000000-0000-0000-0000-000000000000",
                  healthCenterId: props.healthCenterId ?? "",
                  healthCheckTypeId: props.healthCheckTypeId ?? "",
                });
              }}
              noOptionsMessage={() => "Fant ingen kartlegginger"}
            />
          ) : (
            <></>
          )}

          <Checkbox onChange={handleTeacherRoleChange}>Lærer</Checkbox>
          {teacherRole === true ? (
            <Select
              value={assessmentplan?.find((s) => s.id)}
              isSearchable={true}
              options={assessmentplan}
              getOptionValue={(options) => options.assessmentPlanId}
              getOptionLabel={(options) => options.name}
              placeholder="Søk her eller velg direkte fra listen"
              onChange={(value) => {
                setTeacherAssessmentPlan({
                  name: value?.name ?? "",
                  assessmentPlanId: value?.assessmentPlanId ?? "",
                  respondentRoleId: RespondentRoleTypes.teacher.toString(),
                  id:
                    props.assessmentPlan?.id ??
                    "00000000-0000-0000-0000-000000000000",
                  healthCenterId: props.healthCenterId ?? "",
                  healthCheckTypeId: props.healthCheckTypeId ?? "",
                });
              }}
              noOptionsMessage={() => "Fant ingen kartlegginger"}
            />
          ) : (
            <></>
          )}

          <Checkbox onChange={handleKindergartenteacherRoleChange}>
            Barnehagelærer
          </Checkbox>
          {kindergartenteacherRole === true ? (
            <Select
              value={assessmentplan?.find((s) => s.id)}
              isSearchable={true}
              options={assessmentplan}
              getOptionValue={(options) => options.assessmentPlanId}
              getOptionLabel={(options) => options.name}
              placeholder="Søk her eller velg direkte fra listen"
              onChange={(value) => {
                setKindergartenteacherAssessmentPlan({
                  name: value?.name ?? "",
                  assessmentPlanId: value?.assessmentPlanId ?? "",
                  respondentRoleId:
                    RespondentRoleTypes.kindergartenteacher.toString(),
                  id:
                    props.assessmentPlan?.id ??
                    "00000000-0000-0000-0000-000000000000",
                  healthCenterId: props.healthCenterId ?? "",
                  healthCheckTypeId: props.healthCheckTypeId ?? "",
                });
              }}
              noOptionsMessage={() => "Fant ingen kartlegginger"}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Box>
      <Flex
        justifyContent={"space-between"}
        mt={"24px"}
        mb={"24px"}
        width={"60%"}
      >
        <Button
          colorScheme={"blue"}
          padding={"21px 30px"}
          onClick={handleCreateUpdateRole}
          mr={"10px"}
        >
          {!props.selectedRoleAssessmentPlan ? "Legg til" : "Oppdater"}
        </Button>
        <Button
          variant={"outline"}
          colorScheme={"blue"}
          padding={"20px 30px"}
          onClick={() => props.onClose()}
        >
          Avbryt
        </Button>
      </Flex>
    </>
  );
};

export default AdminAddRole;
