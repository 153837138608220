import React, { useEffect, useMemo, useState } from "react";
import {
  useAllHealthcheckTypes,
  useDeleteHealthcheckType,
  useDeleteRespondentRoleAssessmentPlan,
  useGetAllCheckwareAssessmentplan,
  useGetAllCheckwareRespondentGroup,
  useGetAllCheckwareTreatmentType,
  useGetAllRespondentRoleAssessmentPlan,
  useGetRespondentRoleAssessmentPlan,
  usePostHealthcheckType,
  usePostRespondentRoleAssessmentPlan,
  useUser,
} from "../api/hooks";
import {
  Spinner,
  Container,
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Column } from "react-table";
import { HealthcheckType, RespondentRoleAssessmentPlan } from "../types/models";
import { ConfirmModal } from "./ConfirmModal";
import AdminTable from "./AdminTable";
import AdminCreateUpdateHealthCheckType from "./AdminCreateUpdateHealthCheckType";
import AdminAddRole from "./AdminAddRoleModal";
// import AddTestModal from './AddTestModal';

interface TableRows {
  id: string;
  name: string;
  treatment: string;
  respondentGroup: string;
}

function HealthCheckTypeList() {
  // TEST
  // const [showCreateEditTest, setShowCreateEditTest] = useState<boolean>(false);
  ////////////////////////////////////////////////////////////////////

  const toast = useToast();
  // Modaler
  const [showCreateEdit, setShowCreateEdit] = useState<boolean>(false);
  const [showCreateEditRole, setShowCreateEditRole] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmModalRole, setShowConfirmModalRole] =
    useState<boolean>(false);
  // Andre states
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selectedHealthCheckType, setSelectedHealthCheckType] =
    useState<HealthcheckType>();
  const [selectedRoleAssessmentPlan, setSelectedRoleAssessmentPlan] =
    useState<RespondentRoleAssessmentPlan>();
  const [treatmentTypeId, setTreatmentTypeId] = useState("");
  const [respondentGroupId, setRespondentGroupId] = useState("");
  const [healthCenter, setHealthCenter] = useState<string>("");

  let { data: user } = useUser();
  // Checkware
  let { data: treatmentTypes } = useGetAllCheckwareTreatmentType();
  let { data: respondentGroups } = useGetAllCheckwareRespondentGroup();
  let {
    data: healthcheckTypes,
    isLoading: isLoadingHealtCheckTypes,
    refetch: refetchHealthType,
  } = useAllHealthcheckTypes();
  let {
    isLoading: isLoadingDeleteHealthCheckType,
    mutate: deleteHealtcheckType,
  } = useDeleteHealthcheckType();
  let {
    isLoading: isLoadingPostHealthCheckType,
    mutate: postHealthcheckTypes,
  } = usePostHealthcheckType();

  let { data: allRespondentRoleAssessmentPlan } =
    useGetAllRespondentRoleAssessmentPlan(selectedHealthCheckType?.id);
  let { data: oneRespondentRoleAssessmentPlan } =
    useGetRespondentRoleAssessmentPlan(selectedRoleAssessmentPlan?.id);
  let { mutate: deleteRespondentRole } =
    useDeleteRespondentRoleAssessmentPlan();

  useEffect(() => {
    //Set default healthCenter to user's currentHealthCenterId
    setHealthCenter(user?.currentHealthCenterId ?? "");
    setTreatmentTypeId(treatmentTypeId ?? "");
    if (
      allRespondentRoleAssessmentPlan?.length === 0 ||
      allRespondentRoleAssessmentPlan === undefined
    ) {
      setSubmitted(false);
    } else {
      setSubmitted(true);
    }
  }, [user, allRespondentRoleAssessmentPlan]);

  // Checks id of healtchecktypes and match it with checkware for displaying name
  const healtCheck = healthcheckTypes?.map((item1) => {
    const matchingItemRespondentGroup = respondentGroups?.find(
      (item2) => item2.respondentGroupId === item1.respondentGroupId
    );
    const matchingTreatmentType = treatmentTypes?.find(
      (item2) => item2.treatmentTypeId === item1.treatmentTypeId
    );
    return {
      id: item1.id,
      name: item1.name,
      respondentGroupId: matchingItemRespondentGroup
        ? matchingItemRespondentGroup.name
        : "",
      treatmentTypeId: matchingTreatmentType ? matchingTreatmentType.name : "",
    };
  });

  const data: TableRows[] = useMemo(
    () =>
      healtCheck?.map((healtCheck, index) => {
        return {
          id: healtCheck?.id ?? "",
          name: healtCheck?.name ?? "",
          treatment: healtCheck?.treatmentTypeId ?? "",
          respondentGroup: healtCheck.respondentGroupId ?? "",
        };
      }) ?? [],
    [healthcheckTypes, allRespondentRoleAssessmentPlan, healtCheck]
  );

  const columns: Column<TableRows>[] = useMemo(
    () => [
      {
        Header: "Navn på type kontroll",
        accessor: "name",
        disableSortBy: false,
      },
      {
        Header: "Behandlingstype",
        accessor: "treatment",
        disableSortBy: true,
      },
      {
        Header: "Respondentgruppe",
        accessor: "respondentGroup",
        disableSortBy: true,
      },
    ],
    []
  );

  const handleCreateUpdate = (
    healthCheckType: HealthcheckType,
    close: boolean
  ) => {
    let isUpdate =
      healthCheckType.id === "00000000-0000-0000-0000-000000000000"
        ? false
        : true;
    postHealthcheckTypes(healthCheckType, {
      onSuccess: (data) => {
        setShowCreateEdit(close);
        setSelectedHealthCheckType(data);
        toast({
          title: isUpdate
            ? "Type kontroll er oppdatert"
            : "Type kontroll er opprettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });

        refetchHealthType();
      },
      onError(error: any, variables, context) {
        toast({
          title: isUpdate
            ? "Oppdatering av type kontroll feilet"
            : "Opprett type kontroll feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const handleDelete = (id: string) => {
    if (allRespondentRoleAssessmentPlan !== null) {
      allRespondentRoleAssessmentPlan?.forEach((element) => {
        deleteRespondentRole(element.id, {
          onSuccess: (data) => {},
        });
      });
    }
    deleteHealtcheckType(id, {
      onSuccess: (data) => {
        setShowConfirmModal(false);
        toast({
          title: "Type kontroll er slettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        refetchHealthType();
      },
      onError(error: any, variables, context) {
        console.log(error);
        toast({
          title: "Sletting av type kontroll feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };

  const handleDeleteRole = (id: string) => {
    deleteRespondentRole(id, {
      onSuccess: (data) => {
        setShowConfirmModal(false);
        toast({
          title: "Rolle er slettet",
          description: "",
          status: "info",
          isClosable: true,
          position: "top",
        });
        refetchHealthType();
      },
      onError(error: any, variables, context) {
        console.log(error);
        toast({
          title: "Sletting av rolle feilet",
          description: "Vennligst prøv igjen senere",
          status: "error",
          isClosable: true,
          position: "top",
        });
      },
    });
  };
  const handleSubmit = () => {
    // form submission logic here
    allRespondentRoleAssessmentPlan?.length === 0 && setSubmitted(false);
    setSubmitted(true);
  };
  const handleDeleteDisabel = () => {
    if (allRespondentRoleAssessmentPlan?.length === 0) {
      setSubmitted(false);
    }
  };

  if (isLoadingHealtCheckTypes)
    return (
      <Container>
        <Spinner />
      </Container>
    );
  return (
    <>
      <Button
        colorScheme={"blue"}
        padding={"10px,34px,11px,34px"}
        onClick={() => {
          setSelectedHealthCheckType(undefined);
          setTreatmentTypeId("");
          setRespondentGroupId("");
          setShowCreateEdit(true);
          setSubmitted(false);
        }}
      >
        + Legg til type kontroll
      </Button>
      <Box mt={"20px"}>
        <AdminTable
          columns={columns}
          data={data}
          onChange={(id: string) => {
            setSelectedHealthCheckType(
              healthcheckTypes?.find((m) => m.id === id)
            );
            setShowCreateEdit(true);
            handleSubmit();
          }}
          onDelete={(id: string) => {
            setSelectedHealthCheckType(
              healthcheckTypes?.find((h) => h.id === id)
            );
            setShowConfirmModal(true);
          }}
        />
        <Modal
          isOpen={showCreateEdit}
          onClose={() => {
            setShowCreateEdit(false);
          }}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <AdminCreateUpdateHealthCheckType
                onClose={() => {
                  setShowCreateEdit(false);
                }}
                onSubmit={(
                  healthCheckType: HealthcheckType,
                  e: React.FormEvent<HTMLFormElement>
                ) => {
                  handleCreateUpdate(healthCheckType, false);
                  handleSubmit();
                }}
                onSubmitRole={(
                  healthCheckType: HealthcheckType,
                  e: React.FormEvent<HTMLFormElement>
                ) => {
                  setShowCreateEditRole(true);
                  handleCreateUpdate(healthCheckType, true);
                  handleSubmit();
                }}
                treatmentTypes={treatmentTypes}
                respondentGroups={respondentGroups}
                healthCheckType={selectedHealthCheckType}
                selectedTreatmentType={treatmentTypeId}
                setSelectedTreatmentType={setTreatmentTypeId}
                selectedRespondentGroup={respondentGroupId}
                setSelectedRespondentGroup={setRespondentGroupId}
                healthCenter={healthCenter}
                submitted={submitted}
                respondentRole={allRespondentRoleAssessmentPlan!}
                selectedRoleAssessmentPlan={selectedRoleAssessmentPlan}
                // TODO Få onchange til å fungere!
                // onChange={(id: string) => {
                //   console.log(id);
                //   setSelectedRoleAssessmentPlan(
                //     allRespondentRoleAssessmentPlan?.find((h) => (h.id = id))
                //   );
                //   setShowCreateEditRole(true);
                // }}
                onDelete={(id: string) => {
                  setSelectedRoleAssessmentPlan(
                    allRespondentRoleAssessmentPlan?.find((h) => h.id === id)
                  );
                  setShowConfirmModalRole(true);
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={showCreateEditRole}
          onClose={() => {
            setShowCreateEditRole(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <AdminAddRole
                onClose={() => {
                  setShowCreateEditRole(false);
                }}
                healthCenterId={healthCenter}
                treatmentTypeId={treatmentTypeId}
                healthCheckTypeId={selectedHealthCheckType?.id}
                selectedRoleAssessmentPlan={selectedRoleAssessmentPlan}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <ConfirmModal
          show={showConfirmModal}
          onClose={setShowConfirmModal}
          closeButtonText="Avbryt"
          text={`Er du sikker på at du vil slette type kontroll: ${selectedHealthCheckType?.name} ?`}
          onSubmit={() => handleDelete(selectedHealthCheckType?.id ?? "")}
          submitButtonText="Slett"
        />

        <ConfirmModal
          show={showConfirmModalRole}
          onClose={setShowConfirmModalRole}
          closeButtonText="Avbryt"
          text={`Er du sikker på at du vil slette rolle: ${selectedRoleAssessmentPlan?.name} ?`}
          onSubmit={() => {
            handleDeleteRole(selectedRoleAssessmentPlan?.id ?? "");
            handleDeleteDisabel();
          }}
          submitButtonText="Slett"
        />
      </Box>

      {/* KUN FOR TEST  */}
      {/* <Modal
        isOpen={showCreateEditTest}
        onClose={() => {
          setShowCreateEditTest(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <AddTestModal
              onClose={() => {
                setShowCreateEditTest(false);
                setSelectedRoleAssessmentPlan(undefined);
              }}
              onSubmit={(healthCheckType: HealthcheckType) => {
                handleCreateUpdate(healthCheckType, false);
              }}
              onSubmitRole={(healthCheckType: HealthcheckType) => {
                setShowCreateEditRole(true);
                handleCreateUpdate(healthCheckType, true);
              }}
              treatmentTypes={treatmentTypes}
              respondentGroups={respondentGroups}
              healthCheckType={selectedHealthCheckType}
              selectedTreatmentType={treatmentTypeId}
              setSelectedTreatmentType={setTreatmentTypeId}
              selectedRespondentGroup={respondentGroupId}
              setSelectedRespondentGroup={setRespondentGroupId}
              healthCenter={healthCenter}
              roleName={allRespondentRoleAssessmentPlan!}
            />
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
}

export default HealthCheckTypeList;
