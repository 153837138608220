import { env } from '../env';
import { HealthCenterModel } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllHealthCenters = async (): Promise<HealthCenterModel[]> => {
  const response = await fetch(`${baseUrl}/api/admin/healthcenter/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const healthcenters = await response.json();

  return healthcenters;
};

export const postHealthCenter = async (
  healthCenter: HealthCenterModel
): Promise<HealthCenterModel> => {
  const response = await fetch(`${baseUrl}/api/admin/healthcenter`, {
    method:
      healthCenter?.id === '00000000-0000-0000-0000-000000000000'
        ? 'post'
        : 'put',
    body: JSON.stringify(healthCenter),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const healthcenter = await response.json();

  return healthcenter;
};

export const deleteHealthCenter = async (
  healthCenterId: string
): Promise<string> => {
  const response = await fetch(
    `${baseUrl}/api/admin/healthcenter?id=${healthCenterId}`,
    {
      credentials: 'include',
      method: 'delete',
    }
  );

  if (!response.ok) throw new Error(await response.text());

  return healthCenterId;
};

export default {
  getAllHealthCenters,
  postHealthCenter,
  deleteHealthCenter,
};
