import { User } from "oidc-client-ts";
import React from "react";
import { useAuth } from "react-oidc-context";

export type AuthContextType = {
    user: User | null | undefined;
    token: string;
}

const AuthContext = React.createContext<AuthContextType>(
{ user: undefined, token: ''}
);

export const AuthContextProvider: React.FC<{ children: React.ReactNode}> = ({
children
}) => {
const auth = useAuth();

const token =  React.useMemo(() => (
    auth.user ? auth.user.access_token : ''
), [auth]
)

const value = React.useMemo(
    () => ({
    user: auth.user,
    token
    }), [auth]
);

return( <AuthContext.Provider value={value}>{children}</AuthContext.Provider> )
}

export const useAuthContext = (): AuthContextType => {
const context = React.useContext(AuthContext);

if (context === undefined) {
    throw new Error(
        'useContext must be used within a Authcontext provider'
    );
}
return context;
}
  