import { env } from '../env';
import { SchoolClass } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllSchoolClasses = async (): Promise<SchoolClass[]> => {
  const response = await fetch(`${baseUrl}/api/admin/schoolclass/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const schoolClasses = await response.json();

  return schoolClasses;
};

export const postSchoolClass = async (
  schoolClass: any
): Promise<SchoolClass> => {
  const response = await fetch(`${baseUrl}/api/admin/schoolclass`, {
    method:
      schoolClass?.id === '00000000-0000-0000-0000-000000000000'
        ? 'post'
        : 'put',
    body: JSON.stringify(schoolClass),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const schoolClassResponse = await response.json();

  return schoolClassResponse;
};

export const deleteSchoolClass = async (
  schoolClassId: string
): Promise<string> => {
  const response = await fetch(
    `${baseUrl}/api/admin/schoolclass?id=${schoolClassId}`,
    {
      method: 'delete',
      credentials: 'include',
    }
  );

  if (!response.ok) throw new Error(await response.text());

  return schoolClassId;
};

export default {
  getAllSchoolClasses,
  postSchoolClass,
  deleteSchoolClass,
};
