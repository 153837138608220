import React, { useState, useCallback } from "react";
import {
  Heading,
  Button,
  Stack,
  ButtonGroup,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { Respondent } from "../types/models";
import { getAge, getAgeNumber, maskFnr } from "../utilities/HelperFunctions";

type PropTypes = {
  results: Respondent[];
  modal?: Boolean;
  setChosenRespondent: Function;
  onCancel?: Function;
};

function determinePhoneNumberForChild(respondent: Respondent) {
  var result = respondent;
  const birthDate = new Date(result.birthDate);
  var age = getAgeNumber(birthDate);
  if (result.respondentRoleType === "Child" && age != undefined && age <= 13)
    result.phoneNumber = "";
  return result.phoneNumber;
}

function SearchResults(props: PropTypes) {
  const [respondent, setRespondent] = useState<Respondent>();

  const handleChosenRespondent = useCallback(
    (respondent: Respondent | undefined) => {
      {
        if (respondent != undefined) {
          respondent.isEnabled = true;
          props.setChosenRespondent(respondent);
        }
      }
    },
    [respondent]
  );

  return (
    <>
      <Heading size="xs">Søkeresultat</Heading>
      <RadioGroup mt="4" mb="8">
        {props.results && props.results.length == 0 ? "Ingen treff" : null}
        <Stack>
          {props.results.map((result: Respondent) => (
            <Radio
              key={result.id}
              value={result.id}
              colorScheme="blue"
              onChange={(e) => {
                result.phoneNumber = determinePhoneNumberForChild(result);
                props.modal
                  ? handleChosenRespondent(result)
                  : setRespondent(result);
              }}
            >
              {result.name}, {getAge(new Date(result.birthDate))} -{" "}
              {maskFnr(result.nationalIdNumber)}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>

      {!props.modal && (
        <ButtonGroup>
          <Button
            colorScheme="blue"
            variant="solid"
            onClick={() => {
              handleChosenRespondent(respondent);
            }}
          >
            Velg
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              props.onCancel && props.onCancel();
            }}
          >
            Avbryt
          </Button>
        </ButtonGroup>
      )}
    </>
  );
}

export default SearchResults;
