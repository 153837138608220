import { env } from '../env';
import { User } from '../types/models';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllUsers = async (): Promise<User[]> => {
  const response = await fetch(`${baseUrl}/api/user/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const users = await response.json();

  return users;
};

export const getUser = async (): Promise<User> => {
  const response = await fetch(`${baseUrl}/api/user`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const user = await response.json();

  return user;
};

export const postUser = async (user: User): Promise<User> => {
  const response = await fetch(`${baseUrl}/api/user`, {
    method: user.id === '00000000-0000-0000-0000-000000000000' ? 'post' : 'put',
    body: JSON.stringify(user),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const userResponse = await response.json();

  return userResponse;
};

export const deleteUser = async (userId: string): Promise<string> => {
  const response = await fetch(`${baseUrl}/api/user?id=${userId}`, {
    method: 'delete',
    credentials: 'include',
  });

  if (!response.ok) throw new Error(await response.text());

  return userId;
};

export default {
  getAllUsers,
  getUser,
  deleteUser,
  postUser,
};
