import React from "react";
import { ReactElement } from "react";

function UpDownArrowIcon(): ReactElement {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0L0 3.99H3V11H5V3.99H8L4 0ZM11 14.01V7H9V14.01H6L10 18L14 14.01H11Z"
        fill="#4A4A4A"
      />
    </svg>
  );
}

export { UpDownArrowIcon };
