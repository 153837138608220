import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react"; // Importer riktig toast-hook
import { useUserInfo } from "../api/hooks";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute() {
  const { data: userInfo, isLoading } = useUserInfo();
  const toast = useToast();
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setIsUserInfoLoaded(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUserInfoLoaded && !userInfo?.isAuthenticated) {
      toast({
        title: "Du må logge inn",
        description: "Siden krever at du er innlogget",
        status: "info",
        isClosable: true,
        position: "top",
      });
    }
  }, [isUserInfoLoaded, userInfo, toast]);

  if (!isUserInfoLoaded) {
    // Du kan returnere en loader her hvis du ønsker å vise en lasteskjerm
    return <div>Laster...</div>;
  }

  return <>{userInfo?.isAuthenticated ? <Outlet /> : <Navigate to={"/"} />}</>;
}

export default PrivateRoute;
