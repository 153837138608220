import { env } from '../env';

const baseUrl = env.REACT_APP_BASE_URL || '';

export const getAllTreatmentTypes = async (): Promise<any[]> => {
  const response = await fetch(`${baseUrl}/api/admin/treatmenttype/all`, {
    credentials: 'include',
    headers: { Accept: 'application/json' },
  });

  if (!response.ok) throw new Error(await response.text());

  const treatmenttypes = await response.json();

  return treatmenttypes;
};

export const postTreatmentType = async (treatmentType: any): Promise<any> => {
  const response = await fetch(`${baseUrl}/api/admin/treatmenttype`, {
    method:
      treatmentType?.id === '00000000-0000-0000-0000-000000000000'
        ? 'post'
        : 'put',
    body: JSON.stringify(treatmentType),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw new Error(await response.text());

  const treatmenttype = await response.json();

  return treatmenttype;
};

export const deleteTreatmentType = async (
  treatmenttypeId: string,
  access_token: string
): Promise<any> => {
  const response = await fetch(
    `${baseUrl}/api/admin/treatmenttype?id=${treatmenttypeId}`,
    {
      method: 'delete',
      credentials: 'include',
    }
  );

  if (!response.ok) throw new Error(await response.text());

  const treatmenttype = await response.json();

  return treatmenttype;
};

export default {
  getAllTreatmentTypes,
  postTreatmentType,
  deleteTreatmentType,
};
