import React from "react";
import { Box, Link, HStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

export type PropTypes = {
  links: {
    name?: string;
    path: string;
    disabled?: boolean;
  }[];
};

export const Navbar = (props: PropTypes) => {
  const { pathname } = useLocation();

  const { links } = props;

  return (
    <HStack as="nav" spacing={10} justifyContent="center" mt={16}>
      {links
        .filter((link) => !!link.name && !!link.name)
        .map((link) => (
          <Box
            padding={2}
            paddingX={4}
            borderBottom={pathname === link.path ? "2px solid" : "none"}
            borderColor="blue.500"
            key={link.name}
          >
            {!link.disabled && (
              <Link
                as={RouterLink}
                variant="nav"
                color={pathname === link.path ? "blue.500" : "grey.500"}
                fontWeight={pathname === link.path ? "semibold" : "normal"}
                to={link.path}
              >
                {link.name}
              </Link>
            )}
            {link.disabled && (
              <Link
                as={RouterLink}
                variant="nav"
                color="lightgrey"
                fontWeight={pathname === link.path ? "semibold" : "normal"}
                to={link.path}
              >
                {link.name}
              </Link>
            )}
          </Box>
        ))}
    </HStack>
  );
};
