import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

function OidcRedirect() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  });

  return <></>;
}
export default OidcRedirect;
